<template>
    <div class="container">
        <div class="panel">
            <div class="panel-sidebar">
                <the-panel-sidebar/>
            </div>
            <div class="panel-entry">
                <router-view/>
            </div>
        </div>
    </div>
</template>

<script>


import ThePanelSidebar from "@/components/Panel/Section/ThePanelSidebar";

export default {
    name: "PanelPage",
    components: {ThePanelSidebar},
}
</script>

<style lang="scss" scoped>
.panel {
  display: block;
  padding-top: 29px;
  padding-right: 15px;
  padding-left: 15px;

  .panel-sidebar {
    //margin-top: 30%;
    flex: 0 0 auto;
    margin-bottom: 10px;

  }

  .panel-entry {
    flex: 1 0 0;
    overflow: hidden;
  }

  @media screen and (min-width: 576px) {
    display: flex;
    padding-right: 60px;
    padding-left: 60px;

    .panel-sidebar {
      margin-left: 18px;
    }
  }

  &:deep(.main-title) {
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    line-height: 31px;
    text-align: right;
    color: #000000;
    border-bottom: 2px solid #FF7D0E;
    margin-bottom: 25px;
  }

}

</style>