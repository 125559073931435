<template>
    <div class="images">
        <div class="btn-group">
            <button>نمای عمومی بسته</button>
            <button disabled>نمای 3D بسته</button>
            <button disabled>خط تیغ بسته</button>
        </div>

            <the-slider-section />

        <div class="btn-group">
            <button @click="modalIsOpen = true">اشتراک گذاری</button>
            <button disabled>بارگزاری فایل</button>
            <button disabled>دانلود خط تیغ</button>
        </div>


            <TheShareModal  v-if="modalIsOpen" @close="modalIsOpen = false" :modalIsOpen="modalIsOpen" />

    </div>
</template>

<script>
import {categories as fakeCategories} from "@/faker";
import TheShareModal from "@/components/Product/TheShareModal";
import TheSliderSection from "@/components/Product/Section/TheSliderSection";

export default {
    name: "TheProductImage",
    components: {TheSliderSection, TheShareModal},
    data() {
        return {
            modalIsOpen: false,
            category: ""
        }
    }, created() {
        this.category = fakeCategories.find(category => category.slug === this.$route.params.slug);
    }

}
</script>

<style lang="scss" scoped>

.images {
  flex: 1 1 350px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin: 0 13px 20px;
  overflow: hidden;

  .btn-group {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0px 10px;
    width: 100%;

    & > button {
      flex: 1 1 150px;
      background: #414A53;
      border-radius: 5px;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 29px;
      color: #FFFFFF;
      border: 0;
      margin: 0 5px 10px 5px;
      white-space: nowrap;
      max-width: 166px;
      text-align: center;
      padding: 7.5px 21px;
    }
  }



}




</style>