<template>
    <section class="header">
        <the-navbar />
    </section>

</template>

<script>
import TheNavbar from "@/components/Header/TheNavbar";
export default {
    name: "header",
    components : {TheNavbar}
}
</script>

<style scoped lang="scss">

</style>