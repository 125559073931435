<template>
    <login-base>
        <div class="login-header">
            <h4>کد تایید را وارد نمایید.</h4>
        </div>
        <p class="fill-field">کد تایید برای شماره {{ mobile }} ارسال گردید.</p>
        <form @submit.prevent="submitForm">
            <div class="form-group">
                <input type="text"
                       id="mobile"
                       class="form-control"
                       :class="{invalid:validation.message.code}"
                       @input="validate"
                       v-model="code"
                       required>
                <p v-if="validation.message.code">{{validation.message.code}}</p>
            </div>
            <p class="resend">ارسال مجدد کد تا {{ second }} : {{ minute }} دیگر</p>
            <button class="submit-btn" :class="{disabled : validation.error}">ادامه</button>
        </form>
        <button v-if="!intervalId" @click="startTimer()" class="submit-btn">ارسال مجدد</button>
    </login-base>
</template>

<script>
import LoginBase from "@/components/Login/LoginBase";

export default {
    name: "AuthPage",
    components: {LoginBase},
    data() {
        return {
            minute: 1,
            second: 0,
            intervalId: 0,
            mobile:"",
            code:"",
            validation: {
                message: {},
                error: true
            }
        }
    },
    methods: {
        validate() {
            if (this.code === "") {
                this.validation.error = true
                this.validation.message['code'] = "فیلد نمی تواند خالی باشد.";
                return true
            }
            this.validation.error = false
            this.validation.message['code'] = "";
        },
        timer() {
            if (this.second === 0) {
                if (this.minute === 0) {
                    clearInterval(this.intervalId);
                    this.intervalId = false
                    return false;
                }
                this.minute--;
                this.second += 60;
            }
            this.second--;
            return true;
        },
        startTimer(minute = 2, second = 0) {
            this.minute = minute;
            this.second = second;
            this.intervalId = setInterval(this.timer, 1000);
        },
        submitForm() {
            this.$router.push({name:'userInfo'})
            localStorage.setItem('auth', 'true')
        }
    },
    created(){
        this.startTimer();
        this.mobile = localStorage.getItem('mobile')
    },
    watch: {
        $route() {
            clearInterval(this.intervalId);
            this.intervalId = 0;
        }
    }
}
</script>

<style scoped lang="scss">


.login-header {
  margin-bottom: 9px;

  > h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 31px;
    text-align: right;
    color: #000000;
  }
}

.fill-field {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 23px;
  text-align: right;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 23px;
}


.form-group {
  margin-bottom: 42px;

  input.form-control {
    &:valid, &:focus {
      border-color: var(--orange_color);
    }

  }
}

.resend {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 23px;
  color: rgba(0, 0, 0, 0.8);
}

.submit-btn {
  background: #414A53;
  border-radius: 5px;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 29px;
  color: #FFFFFF;
  padding: 6px 0 10px;
  border: 0;
  margin-bottom: 15px;
}

.condition {
  font-style: normal;
  font-weight: normal;
  font-size: 9.5px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  margin: 0;
}


</style>