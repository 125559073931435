<template>
    <!--    <div class="aspect11">-->
    <!--        <swiper-->
    <!--                :slides-per-view="3"-->
    <!--                :space-between="50"-->
    <!--                @swiper="onSwiper"-->
    <!--                @slideChange="onSlideChange"-->
    <!--      -->
    <!--        </swiper>-->
    <div class="slider-section">
        <div class="main-slider aspect11">

            <div class="aspect-entry">
                <swiper :spaceBetween="10"
                        :thumbs="{ swiper: thumbsSwiper }">
                    <swiper-slide><img :src="require('@/assets/images/Laminet_carton.png')"/></swiper-slide>
<!--                    <swiper-slide><img src="https://swiperjs.com/demos/images/nature-1.jpg"/></swiper-slide>-->
<!--                    <swiper-slide><img src="https://swiperjs.com/demos/images/nature-2.jpg"/></swiper-slide>-->
<!--                    <swiper-slide><img src="https://swiperjs.com/demos/images/nature-3.jpg"/></swiper-slide>-->
<!--                    <swiper-slide><img src="https://swiperjs.com/demos/images/nature-4.jpg"/></swiper-slide>-->
<!--                    <swiper-slide><img src="https://swiperjs.com/demos/images/nature-5.jpg"/></swiper-slide>-->
<!--                    <swiper-slide><img src="https://swiperjs.com/demos/images/nature-6.jpg"/></swiper-slide>-->
<!--                    <swiper-slide><img src="https://swiperjs.com/demos/images/nature-7.jpg"/></swiper-slide>-->
<!--                    <swiper-slide><img src="https://swiperjs.com/demos/images/nature-8.jpg"/></swiper-slide>-->
<!--                    <swiper-slide><img src="https://swiperjs.com/demos/images/nature-9.jpg"/></swiper-slide>-->
<!--                    <swiper-slide><img src="https://swiperjs.com/demos/images/nature-10.jpg"/></swiper-slide>-->
                </swiper>
            </div>
        </div>
        <div class="slider-thumb">
            <div class="slider-btn-right">
                <img :src="require('@/assets/images/icon/arrow-head-right-icon.svg')" alt="">
            </div>
            <swiper :style="{'--swiper-navigation-color': '#AAAAAA', '--swiper-navigation-size': '20px'}"
                    @swiper="setThumbsSwiper" :spaceBetween="10" :slidesPerView="'auto'" :freeMode="true"
                    :watchSlidesVisibility="true" :watchSlidesProgress="true" class="mySwiper" :navigation="{ nextEl: '.slider-btn-right', prevEl: '.slider-btn-left', }">
                <swiper-slide><img :src="require('@/assets/images/Laminet_carton.png')"/></swiper-slide>
<!--                <swiper-slide><img src="https://swiperjs.com/demos/images/nature-1.jpg"/></swiper-slide>-->
<!--                <swiper-slide><img src="https://swiperjs.com/demos/images/nature-2.jpg"/></swiper-slide>-->
<!--                <swiper-slide><img src="https://swiperjs.com/demos/images/nature-3.jpg"/></swiper-slide>-->
<!--                <swiper-slide><img src="https://swiperjs.com/demos/images/nature-4.jpg"/></swiper-slide>-->
<!--                <swiper-slide><img src="https://swiperjs.com/demos/images/nature-5.jpg"/></swiper-slide>-->
<!--                <swiper-slide><img src="https://swiperjs.com/demos/images/nature-6.jpg"/></swiper-slide>-->
<!--                <swiper-slide><img src="https://swiperjs.com/demos/images/nature-7.jpg"/></swiper-slide>-->
<!--                <swiper-slide><img src="https://swiperjs.com/demos/images/nature-8.jpg"/></swiper-slide>-->
<!--                <swiper-slide><img src="https://swiperjs.com/demos/images/nature-9.jpg"/></swiper-slide>-->
<!--                <swiper-slide><img src="https://swiperjs.com/demos/images/nature-10.jpg"/></swiper-slide>-->
            </swiper>
            <div class="slider-btn-left">
                <img :src="require('@/assets/images/icon/arrow-head-left-icon.svg')" alt="">
            </div>
        </div>
    </div>
</template>

<script>
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';
import SwiperCore, {Navigation, Thumbs} from 'swiper/core';

// Import Swiper styles
import 'swiper/swiper.scss';
// import "swiper/components/navigation/navigation.min.css"

SwiperCore.use([Navigation, Thumbs]);

export default {
    name: "TheSliderSection",
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            thumbsSwiper: null
        }
    },
    methods: {
        onSwiper(swiper) {
            console.log(swiper);
        },
        onSlideChange() {
            console.log('slide change');
        },
        setThumbsSwiper(swiper) {
            this.thumbsSwiper = swiper;
        },
    },
}

</script>

<style lang="scss" scoped>


.slider-section {
  width: 100%;
  margin-bottom: 20px;

  .main-slider {
    margin-bottom: 20px;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    width: 100%
  }

  .slider-thumb {
    display: flex;
    align-items: center;

    .slider-btn-left {
      margin-right: 16px;
    }

    .slider-btn-right {
        margin-left: 16px;
    }

    > .swiper-container {
      display: flex;

      .swiper-wrapper {
        flex: 1 1 auto;
      }
    }

    .swiper-slide {
      height: 140px !important;
      width: 140px !important;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
      }
    }
  }

  .aspect-entry img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


</style>