<template>
    <div class="feature" :class="{lux: productCategory.isLux}">
        <div class="card">
            <div class="card-header">
                <h6 class="title">مشخصات جعبه را وارد نمایید.</h6>
                <a class="back" @click.prevent="$router.back()">بازگشت</a>
            </div>
            <div class="card-body">
                <form @submit.prevent="submitForm">
                    <div class="form">

                        <component v-for="item in fields"
                                   :key="item.component"
                                   :fieldList="item.fields"
                                   :is="item.component"
                                   :luxable="productCategory.isLux"
                                   v-model:formData="formData"
                        />



                        <TheFormBtn class="form-section"/>
                    </div>

                </form>
            </div>
        </div>
    </div>

</template>

<script>

import TheFormBtn from "@/components/Product/TheFormBtn";
import TheCountSection from "@/components/Product/Section/TheCountSection";
import TheDimensionSection from "@/components/Product/Section/TheDimensionSection";
import TheMaterialSection from "@/components/Product/Section/TheMaterialSection";
import ThePrintServiceSection from "@/components/Product/Section/ThePrintServiceSection";
import TheAfterPrintSection from "@/components/Product/Section/TheAfterPrintSection";
import TheComplementaryServiceSection from "@/components/Product/Section/TheComplementaryServiceSection";
import TheDescriptionSection from "@/components/Product/Section/TheDescriptionSection";
import TheInvoiceSection from "@/components/Product/Section/TheInvoiceSection";
import ThePartBodyBtnSection from "@/components/Product/ThePartBodyBtnSection";
import TheFoilBlockSection from "@/components/Product/Section/TheFoilBlockSection";
import {fields as fake_fields} from "@/faker";

export default {
    name: "TheProductFeature",
    components: {
        TheFoilBlockSection,
        ThePartBodyBtnSection,
        TheInvoiceSection,
        TheDescriptionSection,
        TheComplementaryServiceSection,
        TheAfterPrintSection,
        ThePrintServiceSection,
        TheMaterialSection,
        TheDimensionSection,
        TheCountSection,
        TheFormBtn
    },
    data() {
        return {
            isLux: true,
            formData: {
                length: "",
                width: "",
                height: "",
                spine: "",
                count: "",
                weight: "",
                partBodyName: 'door',
                door: {
                    cardboard: '',
                    grammage: '',
                    combination: "",
                    leafType: "",
                    machine: "",
                    layerCount: "",
                    frontPrint: '',
                    belowPrint: '',
                    firstLayer: '',
                    firstThick: '',
                    secondLayer: '',
                    secondThick: '',
                    thirdLayer: '',
                    thirdThick: '',
                    varnish: '',
                    cellophane: '',
                    uv: '',
                    foilColor: '',
                    foilLength: '',
                    foilWidth: '',
                    letterpress: "",
                    packing: '',
                    lace: "",
                    glue: '',
                    coldSil: "",
                    assemble: ""
                },
                floor: {
                    cardboard: '',
                    grammage: '',
                    combination: "",
                    leafType: "",
                    machine: "",
                    layerCount: "",
                    frontPrint: '',
                    belowPrint: '',
                    firstLayer: '',
                    firstThick: '',
                    secondLayer: '',
                    secondThick: '',
                    thirdLayer: '',
                    thirdThick: '',
                    varnish: '',
                    cellophane: '',
                    uv: '',
                    foilColor: '',
                    foilLength: '',
                    foilWidth: '',
                    letterpress: "",
                    packing: '',
                    lace: "",
                    glue: '',
                    coldSil: "",
                    assemble: ""
                },
                tray: {
                    cardboard: '',
                    grammage: '',
                    combination: "",
                    leafType: "",
                    machine: "",
                    layerCount: "",
                    frontPrint: '',
                    belowPrint: '',
                    firstLayer: '',
                    firstThick: '',
                    secondLayer: '',
                    secondThick: '',
                    thirdLayer: '',
                    thirdThick: '',
                    varnish: '',
                    cellophane: '',
                    uv: '',
                    foilColor: '',
                    foilLength: '',
                    foilWidth: '',
                    letterpress: "",
                    packing: '',
                    lace: "",
                    glue: '',
                    coldSil: "",
                    assemble: ""
                },
                behindDoor: {
                    cardboard: '',
                    grammage: '',
                    combination: "",
                    leafType: "",
                    machine: "",
                    layerCount: "",
                    frontPrint: '',
                    belowPrint: '',
                    firstLayer: '',
                    firstThick: '',
                    secondLayer: '',
                    secondThick: '',
                    thirdLayer: '',
                    thirdThick: '',
                    varnish: '',
                    cellophane: '',
                    uv: '',
                    foilColor: '',
                    foilLength: '',
                    foilWidth: '',
                    letterpress: "",
                    packing: '',
                    lace: "",
                    glue: '',
                    coldSil: "",
                    assemble: ""
                },
                invoice: '',
            },
            fields:
                [
                    {
                        component: 'TheDimensionSection',
                        fields: [
                            {
                                name: 'length',
                                label: 'طول',
                                type: 'number'
                            }, {
                                name: 'width',
                                label: 'عرض',
                                type: 'number'
                            }, {
                                name: 'height',
                                label: 'ارتفاع',
                                type: 'number'
                            }, {
                                name: 'spine',
                                label: 'عطف',
                                type: 'number'
                            }
                        ],
                    },
                    {
                        component: 'TheCountSection',
                        fields: [
                            {
                                name: 'count',
                                label: 'تیراژ',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        component: 'ThePartBodyBtnSection',
                        fields: {},
                        luxable: true
                    },
                    {
                        component: 'TheMaterialSection',
                        fields: [
                            {
                                name: "cardboard",
                                label: 'مقوا',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                            {
                                name: "grammage",
                                label: 'گرماژ',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            }, {
                                name: "leafType",
                                label: 'نوع ورق',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ],
                            }, {
                                name: "combination",
                                label: 'ترکیب',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ],
                            }, {
                                name: "machine",
                                label: 'ماشین آلات',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ],
                            }, {
                                name: "layerCount",
                                label: 'تعداد لایه',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ],
                            },
                            // {
                            //     name: "cardboard",
                            //     label: 'مقوا',
                            //     type: 'select',
                            //     values: [
                            //         {
                            //             name: 1000,
                            //             value: 1000
                            //         }, {
                            //             name: 2000,
                            //             value: 2000
                            //         },
                            //     ]
                            // },
                        ],
                        luxable: true
                    },
                    {
                        component: 'ThePrintServiceSection',
                        fields: [
                            {
                                name: "frontPrint",
                                label: 'چاپ رو',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                            {
                                name: "belowPrint",
                                label: 'چاپ زیر',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                            {
                                name: "firstLayer",
                                label: 'لایه اول',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                            {
                                name: "firstThick",
                                label: 'میکرون',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                            {
                                name: "secondLayer",
                                label: 'لایه دوم',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                            {
                                name: "secondThick",
                                label: 'میکرون',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                            {
                                name: "thirdLayer",
                                label: 'لایه سوم',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                            {
                                name: "thirdThick",
                                label: 'میکرون',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },

                        ],
                        luxable: true
                    },
                    {
                        component: 'TheAfterPrintSection',
                        fields: [
                            {
                                name: "varnish",
                                label: 'ورنی',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                            {
                                name: "cellophane",
                                label: 'سلفون',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                            {
                                name: "uv",
                                label: 'یو وی',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                            {
                                name: "connection",
                                label: 'اتصالات',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                            {
                                name: "print",
                                label: 'چاپ',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                        ],
                        luxable: true
                    },
                    {
                        component: 'TheFoilBlockSection',
                        fields: [
                            {
                                name: "foilColor",
                                label: 'رنگ',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                            {
                                name: "foilLength",
                                label: 'طول',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                            {
                                name: "foilWidth",
                                label: 'عرض',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                        ],
                        luxable: true
                    },
                    {
                        component: 'TheComplementaryServiceSection',
                        fields: [
                            {
                                name: "letterpress",
                                label: 'برجسته',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                            {
                                name: "packing",
                                label: 'بسته بندی',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                            {
                                name: "lace",
                                label: 'بند',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                            {
                                name: "glue",
                                label: 'چسب',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                            {
                                name: "coldSil",
                                label: 'کلدسیل',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                            {
                                name: "assemble",
                                label: 'منتاژ',
                                type: 'select',
                                values: [
                                    {
                                        name: 1000,
                                        value: 1000
                                    }, {
                                        name: 2000,
                                        value: 2000
                                    },
                                ]
                            },
                        ],
                        luxable: true
                    },
                    {
                        component: 'TheInvoiceSection',
                        fields:
                            [
                                {
                                    name: "invoice",
                                    label: 'فاکتور',
                                    type: 'text'
                                },

                            ]
                    },
                    {component: 'TheDescriptionSection', fields: {}},
                ]
            ,
            existFields: []
        }
    },
    props: ['productCategory'],
    methods: {
        submitForm() {
        }
    },
    created() {
        let item = fake_fields.find(i => i.category_id === this.productCategory.parent_id)
        this.existFields = item ? item.fields : [];
    }
}
</script>

<style lang="scss" scoped>

.feature {
  flex: 1 1 550px;
  width: 100%;
  margin: 0 13px 20px;

}

.card {
  background: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.card-header {
  background: #414A53;
  border-radius: 5px 5px 0px 0px;
  color: var(--white_color);
  display: flex;
  justify-content: space-between;
  padding: 8px 20px 10px 35px;

  @media screen and (min-width: 576px) {
    padding: 8px 36px 10px 67px;
  }

  .title {
    margin: 0 0 0 20px;
    font-size: 14px;
    line-height: 27px;
    text-align: right;
    color: #FFFFFF;
  }

  .back {
    font-size: 14px;
    line-height: 27px;
    text-align: right;
    color: #FFFFFF;
    position: relative;
    cursor: pointer;

    &:after {
      content: "";
      width: 16px;
      height: 11px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='11' viewBox='0 0 16 11' fill='white'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.22638 6.40327H14.2408C15.4777 6.40327 15.4777 4.53845 14.2408 4.53845H3.22638L6.23031 1.62467C7.11382 0.750539 5.75911 -0.589799 4.8756 0.284335L0.340251 4.77155C-0.0720534 5.12121 -0.130954 5.76224 0.28135 6.17017L4.8756 10.7157C5.75911 11.5898 7.05492 10.2495 6.17141 9.37533L3.22638 6.40327Z'/%3E%3C/svg%3E%0A");
      position: absolute;
      left: -26px;
      top: 8px;
    }
  }
}

.card-body {
  padding: 16px 0 38px;
  // todo must change
}

.form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
}

.lux:deep(.lux-item) {

  background-color: var(--light_yellow_color);
  padding-top: 28px;

  .form-control {
    background-color: var(--light_yellow_color);
  }

  .section-title {
    background-color: var(--light_yellow_color);
  }

  label {
    background-color: var(--light_yellow_color);
  }
}



.form-section {

  padding: 10px 10px 0;
  @media screen and (min-width: 768px) {
    padding: 10px 38px 0;
  }


  &:deep(.section-title) {
    width: 100%;
    margin: 0 0 20px;
    padding: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 29px;
    text-align: right;
    color: #000000;
    position: relative;
    background: var(--white_color);
    display: flex;
    align-items: center;
    white-space: nowrap;

    &:after {
      content: "";
      height: 0.5px;
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      margin-right: 16px;
    }
  }

  &:deep(.section-fields) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7.5px;

    > .form-group {
      flex: 1 0 250px;
      margin: 0 7.5px 15px;

    }

  }


  &#dimension .section-fields > .form-group {
    flex: 1 0 220px;
  }

  select.form-control option:first-child {
    display: none;
  }
}

</style>