<template>
    <div class="form-section" v-if="luxable" :class="{'lux-item':luxable}">
        <div class="btn-group">
            <div class="btn-item">
                <button type="button"
                        :class="{active : formData.partBodyName === 'door'}"
                        @click.prevent="updateFormData('door')"
                >درب
                </button>
            </div>
            <div class="btn-item">
                <button type="button"
                        :class="{active : formData.partBodyName === 'floor'}"
                        @click.prevent="updateFormData('floor')"
                >کف
                </button>
            </div>
            <div class="btn-item">
                <button type="button"
                        :class="{active : formData.partBodyName === 'tray'}"
                        @click.prevent="updateFormData('tray')"
                >سینی
                </button>
            </div>
            <div class="btn-item">
                <button type="button"
                        :class="{active : formData.partBodyName === 'behindDoor'}"
                        @click.prevent="updateFormData('behindDoor')"
                >پشت درب
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ThePartBodyBtnSection",
    props: ['formData', 'luxable'],
    emits: ['update:formData'],
    methods: {
        updateFormData(a) {
            let formData = this.formData;
            formData.partBodyName = a;
            this.$emit('update:formData', formData)
        },
        // active(option) {
        //     this.$emit('updateFormData', option, 'partBodyName');
        // }
    }
}
</script>

<style lang="scss" scoped>
.btn-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -5px 10px;

  .btn-item {
    flex: 1 0 auto;
    margin: 0 5px;
  }

  button {
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    border-radius: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 29px;
    color: #000000;
    margin: 0 5px 10px 5px;
    white-space: nowrap;
    max-width: 200px;
    text-align: center;
    padding: 9px 20px;
    width: 100%;

    &.active {
      background: #FFB700;
    }
  }
}
</style>