<template>
    <main class="product container">
        <div class="product-entry">

            <div class="feature-wrap row">
                <the-product-feature :productCategory="subcategory"/>

                <the-product-image/>

            </div>

            <TheMoreProductSection />
        </div>
    </main>
</template>

<script>
import TheProductFeature from "@/components/Product/TheProductFeature";
import TheProductImage from "@/components/Product/TheProductImage";
import {categories} from "@/faker";
import TheMoreProductSection from "@/components/Product/Section/TheMoreProductSection";

export default {
    name: "ProductPage",
    components: {TheMoreProductSection, TheProductImage, TheProductFeature},
    data() {
        return {
            subcategory : ""
        }
    },
    created() {
        this.subcategory = categories.find(category => category.slug === this.$route.params.slug);
    }
}
</script>

<style lang="scss" scoped>
.product {
  background: var(--white_color);
  padding-top: 32px;

  .product-entry {
    display: flex;
    flex-direction: column;
  }

  .feature-wrap {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 18px;
  }


}
</style>