<template>
    <nav class="navbar container">
        <div class="navbar-entry">
            <div class="sidebar-btn">
                <button @click="sidebarIsOpen = !sidebarIsOpen">
                    <img :src=" require('@/assets/images/icon/hamburger-icon.svg')" alt="">
                </button>
            </div>
            <div class="brand">
                <p class="catchword">مرجع استعلام قیمت بسته بندی</p>
                <div class="logo">
                    <a href="/" @click.prevent="$router.push('/')">
                        <img :src="require('@/assets/images/svg/sizpack.svg')" alt="sizpack logo">
                    </a>
                </div>
            </div>
            <div class="middle-menu">
                <menu-dropdown/>
                <div class="search-form">
                    <form action="#">
                        <input class="form-input"
                               type="text"
                               name=""
                               placeholder="جستجو محصولات، صفحات و الگو ها">
                        <button class="search-btn" type="button">
                            <img :src="require('@/assets/images/icon/magnify-icon.svg')" alt="">
                        </button>
                    </form>
                </div>
            </div>

            <the-navigation/>

        </div>

    </nav>
    <teleport to="body">
        <the-sidebar  @closeSidebar="sidebarIsOpen = !sidebarIsOpen"
                     :sidebarIsOpen="sidebarIsOpen"/>
    </teleport>
</template>

<script>
import MenuDropdown from "@/components/Header/MenuDropdown";
import TheNavigation from "@/components/Header/TheNavigation";
import TheSidebar from "@/components/Header/TheSidebar";

export default {
    name: "TheNavbar",
    components: {TheSidebar, TheNavigation, MenuDropdown},
    data() {
        return {
            sidebarIsOpen: false
        }
    }, methods: {}

}
</script>

<style scoped lang="scss">

.sidebar-btn > button {
  display: inline-block;
  color: var(--secondary);
  text-align: center;
  cursor: pointer;
  background-color: var(--white_color);
  border: 2px solid var(--secondary_color);
  border-radius: 5px;
  padding: 5px;
}

.navbar {
  position: relative;
  z-index: 10;
  box-shadow: 0 3px 10px 0 #00000040;
  background: var(--white_color);
}

.navbar-entry {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px;
  @media screen and (min-width: 576px) {
  }
  @media screen and (min-width: 992px) {
    justify-content: space-around;
    margin: 0 40px;
    align-items: flex-end;
  }


  > div {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }

}

.brand {
  display: flex;
  align-items: flex-end;
  margin-top: 5px;
  margin-bottom: 5px;
  justify-content: flex-end;

  @media screen and (min-width: 992px) {
    margin-top: 12px;
    margin-bottom: 20px;
  }

  .catchword {
    margin-left: 10px;
    display: none;

    @media screen and (min-width: 576px) {
      display: block;
    }
  }

}

.middle-menu, .navigation-menu {
  display: none;
}

.middle-menu {
  position: relative;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
  margin: 0 20px 25px;

}

.search-form {
  flex-grow: 1;
  max-width: 343px;

  form {
    display: flex;

    .form-input {
      display: block;
      width: 100%;
      padding: 8px 13px 9px 0;
      font-size: 12px;
      font-weight: 400;
      line-height: 23px;
      color: #212529;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #000000;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
      border-radius: 0 5px 5px 0;
    }

    .search-btn {
      border: 0;
      border-radius: 5px 0 0 5px;
      background: var(--secondary_color);
      padding: 6px 10px;
    }

  }
}

.navigation-menu {
  justify-content: flex-start;
  margin-bottom: 20px;
}

@media (min-width: 992px) {

  .navbar {
    flex-direction: row;
  }

  .middle-menu {
    display: flex;
  }

  .navigation-menu {
    display: flex;
  }

  .sidebar-btn {
    display: none;
  }

}


</style>