<template>
    <div class="category-item">
        <div class="card">
            <div class="card-body">
                <div class="aspect11">
                    <img :src="src" alt="">
                </div>
            </div>
            <div class="card-footer">
                <h4 class="title">
                    {{ title }}
                </h4>
                <a class="link" :href="href" @click.prevent="$router.push(href)">مشاهده > </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CategoryItem",
    props: ['title', 'href', 'src']
}
</script>

<style scoped lang="scss">
.category-item {
  flex: 1 1 270px;
  max-width: 312px;
  margin: 0 9px 17px 9px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #FFFFFF;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.card-body {
  flex: 1 1 auto;
}

.card-footer {
  padding: 10px 23px;

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 27px;
    text-align: right;
    color: #000000;
    margin: 0 0 4px;
  }

  .link {
    font-size: 13px;
    line-height: 25px;
    text-align: right;
    color: var(--orange_color);
  }

}
</style>