<template>
    <div class="card">
        <div class="card-body">
            <div class="aspect11">
                <img :src="src" alt="">
            </div>
        </div>
        <a :href="href" @click.prevent="$router.push(href)" class="card-btn">
            {{ btnTitle }}
        </a>
    </div>
</template>

<script>
export default {
    name: "CategoryCard",
    props: ['src', 'name', 'href'],
    computed: {
        btnTitle() {
            return "استعلام قیمت " + this.name;
        }
    },
    methods: {
        test(event) {
            console.log(event.target.href)
        }
    }
}
</script>

<style scoped lang="scss">

.card {
  margin-bottom: 10px; // todo change
}

.card-btn {
  width: 100%;
  background: var(--secondary_color);
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 34px;
  color: #FFFFFF;
  border: 0;
  border-radius: 5px 5px 0 0;
  display: inline-block;
  text-align: center;
  margin-top: -5px;
  padding: 8px;
  position: relative;
  transition: color 0.5s ease-out;

  &:hover {
    color: var(--primary_color);
  }
}
</style>