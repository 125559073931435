<template>
    <div class="my-order">
        <h6 class="main-title">سفارش های من</h6>
        <div class="my-order-filter">
            <form @click.prevent="">
                <div class="filter-fields">
                    <div class="form-group">
                        <select id="condition" class="form-control" v-model="condition" required>
                            <option value="" selected disabled></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </select>
                        <label for="condition">جستجوی وضعیت</label>
                    </div>
                    <div class="form-group">
                        <select id="level" class="form-control" v-model="level" required>
                            <option value="" selected disabled></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </select>
                        <label for="level">طبقه</label>
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" id="name" v-model="name" required>
                        <label for="name">نام کالا</label>
                    </div>
                    <div class="form-group">
                        <input class="form-control" id="invoiceId" type="text" v-model="invoiceId" required>
                        <label for="invoiceId">شماره فاکتور</label>
                    </div>

                    <date-picker-base v-model="datea"/>
                    <date-picker-base v-model="dateb"/>


                    <DatePicker v-model="dateb" class="field-item"/>

                    <button class="search-btn">جستجو</button>
                </div>
            </form>
        </div>


        <div class="my-order-table">
            <table class="table">
                <thead>
                <tr>
                    <th v-for="item in tableTitle" :key="item" scope="col">{{ item }}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>Otto</td>
                    <td>Otto</td>
                    <td>Otto</td>
                    <td>Otto</td>
                </tr>
                <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                </tbody>
            </table>
        </div>
        <div></div>
        <div class="more-report">مشاهده بیشتر گزارشات</div>

    </div>

</template>

<script>
import DatePickerBase from "@/components/Bases/DatePickerBase";


export default {
    name: "MyOrderPage",
    data() {
        return {
            documentUploaderIsActive: false,
            name: "",
            level: "",
            condition: "",
            invoiceId: "",
            description: "",
            datea: "",
            dateb: "",
            tableTitle: ['ردیف', 'طبقه', 'نام کالا', 'شماره سفارش', 'تاریخ تحویل', 'وضعیت سفارش', 'جزئیات']
        }
    }, components: {
        DatePickerBase
    }, methods: {
        // test(x) {
        //     console.log(x)
        // }
    }
}
</script>

<style lang="scss" scoped>
.my-order {
  margin-bottom: 187px;

  .my-order-filter {
    margin-bottom: 30px;
  }

  .filter-fields {
    display: grid;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

    .form-control, .date-control > input {
      background-color: transparent;
    }

    .form-control~label, .form-control:focus ~ label, .form-control:valid ~ label {
      background-color: #F3F3F3;
    }
  }


  .search-btn {
    background: #414A53;
    border-radius: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 29px;
    color: #FFFFFF;
    padding: 8px 50px;
    border: none;
    text-align: center;
    grid-column: -1/-2;
  }

  .my-order-table {
    overflow-x: auto;
    width: 100%;
  }

  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: #dee2e6;
    background: #F7F7F7;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    thead {
      background: #414A53;
      border-radius: 5px 5px 0 0;

      th {
        padding: 9px 30px;
        //border-radius: 5px 5px 0 0;
        border-left: 1px solid #FFFFFF;
        border-right: 1px solid #FFFFFF;
        font-size: 14px;
        line-height: 27px;
        text-align: right;
        color: #FFFFFF;
        white-space: nowrap;
        font-style: normal;
        font-weight: normal;

        &:first-child {
          border-top-right-radius: 5px;
          padding: 9px 20px;
        }

        &:last-child {
          border-top-left-radius: 5px;
        }

        &:nth-child(3) {
          padding: 9px 60px;
        }
      }
    }

    tbody {
      td, & th {
        text-align: center;
        padding: 15px 30px 10px;
        border-right: 1px solid rgba(65, 74, 83, 0.5);
        border-left: 1px solid rgba(65, 74, 83, 0.5);
        border-bottom: 1px solid rgba(65, 74, 83, 0.5);
        min-height: 50px;
      }
    }
  }

  .more-report {
    background: #FFD2A6;
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 27px;
    color: #1E1E1E;
    padding: 9px 0;
    text-align: center;
  }


}

</style>
