<template>
    <transition name="fadeModal">
        <div class="modal-wrap" v-if="modalIsOpen" @click.self="close">
            <div class="modal" >
                <div class="modal-header">
                    <h6 class="title">
                        <slot name="header"></slot>
                    </h6>
                    <button class="close" @click="close">x</button>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
            </div>
        </div>
    </transition>

</template>

<script>
export default {
    name: "ModalBase",
    props: ['modalIsOpen'],
    emits: ['close'],
    data() {
        return {}
    },
    methods: {
        close() {
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss" scoped>
.modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.modal {
  width: 100%;
  max-width: 576px;
  max-height: 95vh;
  //padding: 22px 34px;
  background: #FFFFFF;
  border-radius: 10px;
  position: relative;
  z-index: 1001;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  padding-bottom: 15px;
  border-bottom: 1px solid #000000;
  margin-bottom: 24px;
  margin-right: 34px;
  margin-left: 34px;
  padding-top: 22px;

  .title {
    flex: 1 0 auto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 25px;
    color: #000000;
    margin: 0;
    padding: 0;
  }

  .close {
    flex: 0 0 auto;
    color: var(--black_color);
    font-size: 22px;
    border: none;
    background-color: transparent;
    line-height: 10px;
  }
}

.fadeModal-enter-active,
.fadeModal-leave-active {
  transition: opacity 0.5s ease;
}

.fadeModal-enter-from,
.fadeModal-leave-to {
  opacity: 0;
}


</style>