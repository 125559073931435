<template>
    <div class="form-section">
        <h6 class="section-title">
            پیش فاکتور
        </h6>
        <div class="section-fields">
            <the-input v-for="item in fieldList"
                       :key="item.name"
                       :model-value="formData[item.name]"
                       :item="item"
                       @input="updateFormData(item.name,$event)"
            />

        </div>
        <div class="section-fields">
            <div class="form-group">
                <div class="form-control-text">
                    <span class="label">جمع کل:</span>
                    <span class="content">238500000</span>
                    <span class="unit">ریال</span>
                </div>
            </div>
            <div class="form-group">
                <div class="form-control-text">
                    <span class="label">فی هر عدد:</span>
                    <span class="content">53408</span>
                    <span class="unit">ریال</span>
                </div>
            </div>
            <div class="form-group">
                <div class="form-control-text">
                    <span class="label">تخفیف رتبه بندی:</span>
                    <span class="content">53408</span>
                    <span class="unit">ریال</span>
                </div>
            </div>
            <div class="form-group">
                <div class="form-control-text">
                    <span class="label">زمان تحویل:</span>
                    <span class="content">13</span>
                    <span class="unit">روز کاری</span>
                </div>
            </div>
            <div class="form-group">
                <div class="form-control-text">
                    <span class="label">مبلغ قابل پرداخت:</span>
                    <span class="content">238500000</span>
                    <span class="unit">ریال</span>
                </div>
            </div>
            <div class="form-group">
                <div class="form-control-text">
                    <span class="label">پیش پرداخت:</span>
                    <span class="content">163580000</span>
                    <span class="unit">ریال</span>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import TheInput from "@/components/Product/TheInput";
export default {
    name: "TheInvoiceSection",
    components: {TheInput},
    props: ['formData', 'fieldList'],
    emits:['update:formData'],
    methods: {
        updateFormData(field, e) {
            let a = this.formData
            a[field] = e
            this.$emit('update:formData', a)
        }
    }

}
</script>

<style scoped>

</style>