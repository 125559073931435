<template>
    <div @click="answerIsOpen = !answerIsOpen" class="faq-item">
        <div class="question"><span>{{ number }} -</span> {{ faq.question }}</div>
        <transition name="answerFade">
            <div class="answer" v-if="answerIsOpen">{{ faq.answer }}</div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "TheFaqItem",
    props: ['number', 'faq'],
    data() {
        return {
            answerIsOpen : false
        }
    }
}
</script>

<style lang="scss" scoped>
.faq-item {
  border-bottom: 0.5px solid rgba(255, 125, 14, 0.5);
  padding-top: 14px;
  padding-bottom: 18px;
  cursor: pointer;

  .question {
    font-weight: normal;
    font-size: 16px;
    line-height: 31px;
    text-align: right;
    color: #000000;
    margin-bottom: 14px;
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.07225 5.29292L8.31323 1.44583C8.72572 0.957583 8.38058 0.199951 7.73238 0.199951H1.25042C0.610641 0.199951 0.257079 0.949165 0.669568 1.44583L3.91055 5.29292C4.2136 5.64648 4.7692 5.64648 5.07225 5.29292Z' fill='%23414A53'/%3E%3C/svg%3E%0A");
    background-position: 16px 13px;
    background-repeat: no-repeat;
  }

  .answer {
    font-weight: normal;
    font-size: 13px;
    line-height: 25px;
    text-align: right;
    margin-top: 14px;
    margin-bottom: 6px;
    color: rgba(0, 0, 0, 0.7);
  }
  .answerFade-enter-active,
  .answerFade-leave-active {
    transition: opacity 0.4s ease;
  }

  .answerFade-enter-from,
  .answerFade-leave-to {
    opacity: 0;
  }
}

</style>