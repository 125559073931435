<template>
    <login-base>
        <div class="login-header">
            <h4>ورود</h4>
        </div>
        <p class="fill-field">شماره موبایل خود را وارد نمایید.</p>
        <form action="#" @submit.prevent="submitForm">
            <div class="form-group">
                <input v-model="mobile"
                       @input="validate"
                       type="text"
                       id="mobile"
                       :class="{invalid:validation.message.mobile}"
                       class="form-control" required>
                <label for="mobile">شماره موبایل</label>
                <p v-if="validation.message.mobile">{{ validation.message.mobile }}</p>
            </div>
            <button class="submit-btn" :class="{disabled : validation.error}">ورود</button>
            <p class="condition">
                با ورود و یا ثبت نام در سیزپک شما شرایط و قوانین استفاده از سرویس های سایت سیزپک و قوانین حریم خصوصی
                آن را می پذیرد.
            </p>
        </form>
    </login-base>
</template>

<script>
import LoginBase from "@/components/Login/LoginBase";

export default {
    name: "LoginPage",
    components: {LoginBase},
    data() {
        return {
            mobile: 0,
            validation: {
                message: {},
                error: true
            }
        }
    },
    methods: {
        validate() {
            if (this.mobile === "") {
                this.validation.message['mobile'] = "فیلد موبایل نمی تواند خالی باشد.";
                this.validation.error = true;
                return true;
            }
            const pattern = /^09[0-9]{9}$/;
            if (!pattern.test(this.mobile)) {
                this.validation.message['mobile'] = "شماره موبایل وارد شده صحیح نمی باشد.";
                this.validation.error = true;
                return true;
            }
            this.validation.message['mobile'] = "";
            this.validation.error = false;
        },
        submitForm() {
            localStorage.setItem('mobile',this.mobile)
            localStorage.setItem('code','123')
            this.$router.push({name: 'auth'})
        }
    }
}
</script>

<style scoped lang="scss">


.login-header {
  text-align: center;
  margin-bottom: 32px;

  > h4 {
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 31px;
    text-align: center;
    color: var(--black_color);
    padding: 11px 29px;
    border-bottom: 0.3px solid #000000;
    margin: 0;
  }
}

.fill-field {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 21px;
  text-align: right;
  color: #000000;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 25px;

  input.form-control {
    &:valid, &:focus {
      border-color: var(--orange_color);

      ~ label {
        color: var(--orange_color);
      }
    }
  }
}

.submit-btn {
  background: #414A53;
  border-radius: 5px;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 29px;
  color: #FFFFFF;
  padding: 6px 0 10px;
  border: 0;
  margin-bottom: 15px;
}

.condition {
  font-style: normal;
  font-weight: normal;
  font-size: 9.5px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  margin: 0;
}


</style>