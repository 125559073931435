<template>

    <the-header v-if="withoutHeader"/>

    <router-view/>
    <!--    <home-page/>-->
    <the-footer v-if="withoutHeader" :is-dark="lightFooter"/>
</template>

<script>
import TheHeader from "@/components/Header/TheHeader";
import TheFooter from "@/components/Footer/TheFooter";
// import HomePage from "@/components/Home/HomePage";

const withoutHeaderUrl = ['/login', '/auth'];
const lightFooterUrl = ['/'];
export default {
    name: 'App',
    components: {
        TheHeader,
        TheFooter

    }, computed: {
        withoutHeader() {
            if (withoutHeaderUrl.includes(this.$route.path)) {
                return false;
            }
            return true;
        },
        lightFooter() {
            if (lightFooterUrl.includes(this.$route.path)) {
                return false;
            }
            return true;
        }
    }
}
</script>

<style lang="scss">
@import "./scss/reboot";
//@import "../scss/bootstrap-grid";
@import "./scss/style";


.aspect11 {
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;

  > img, >div {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: $laptop_breakpoint) {

}


</style>
