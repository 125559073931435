<template>
    <div class="gallery-page container">
        <div class="page-entry">
            <div class="btn-group">
                <button class="btn active">همه موارد</button>
                <button class="btn" v-for="btnName in btnNames" :key="btnName.id">{{ btnName.name }}</button>
            </div>
            <div class="gallery-wrap">
                <div class="gallery">
                    <img :src="require('@/assets/images/gallery12.png')" class="a12">
                    <img :src="require('@/assets/images/gallery11.png')" class="a11">
                    <img :src="require('@/assets/images/gallery11.png')" class="a11">
                    <img :src="require('@/assets/images/gallery11.png')" class="a11">
                    <img :src="require('@/assets/images/gallery11.png')" class="a11">
                    <img :src="require('@/assets/images/gallery21.png')" class="a21">
                    <img :src="require('@/assets/images/gallery11.png')" class="a11">
                    <img :src="require('@/assets/images/gallery21.png')" class="a21">
                    <img :src="require('@/assets/images/gallery11.png')" class="a11">
                    <img :src="require('@/assets/images/gallery11.png')" class="a11">
                    <img :src="require('@/assets/images/gallery11.png')" class="a11">
                    <img :src="require('@/assets/images/gallery12.png')" class="a12">
                    <img :src="require('@/assets/images/gallery12.png')" class="a12">
                    <img :src="require('@/assets/images/gallery11.png')" class="a11">
                    <img :src="require('@/assets/images/gallery11.png')" class="a11">

                </div>
            </div>
            <div class="pagination">
                <div class="pagination-item">&#60;</div>
                <div class="pagination-item">1</div>
                <div class="pagination-item">2</div>
                <div class="pagination-item">3</div>
                <div class="pagination-item">...</div>
                <div class="pagination-item">&#62;</div>
            </div>
        </div>
    </div>
</template>

<script>
import {categories} from "@/faker";

export default {
    name: "GalleryPage",
    data() {
        return {
            btnNames: []
        }
    },
    created() {
        this.btnNames = categories.filter(i => i.parent_id == 0);

    }
}
</script>

<style lang="scss" scoped>
.gallery-page {
  padding-top: 55px;

  .btn-group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
    margin-right: -1px;
    margin-left: -1px;

    .btn {
      flex: 0 0 128px;
      background: #414A53;
      border-radius: 5px;
      font-weight: normal;
      font-size: 14px;
      line-height: 27px;
      color: #FFFFFF;
      text-align: center;
      padding: 7px 0;
      border: none;
      margin: 0 1px;

      &.active {
        background: #FFB700;
        color: #1E1E1E;
      }
    }
  }

  .gallery {
    display: grid;
    grid-auto-flow: row dense;
    gap: 3px / 3px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

    .a11, .a12, .a21 {
      display: inline-block;
      width: 100%;
      height: auto;
    }

    .a21 {
      grid-row-end: 2 span;
    }

    .a12 {
      grid-column-end: 2 span;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 41px;
    margin-top: 30px;


    .pagination-item {
      border: 2px solid rgba(0, 0, 0, 0.7);
      font-style: normal;
      font-weight: normal;
      text-align: center;
      font-size: 17px;
      line-height: 32px;
      color: rgba(0, 0, 0, 0.7);
      border-radius: 50%;
      height: 29px;
      width: 29px;
      margin-right: 5px;
      margin-left: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

}
</style>