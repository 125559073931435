<template>
    <teleport to="body">
        <modal-base class="share-modal" :modal-is-open="modalIsOpen" @close="$emit('close',$event)">
            <template v-slot:header>
                اشتراک گذاری
            </template>
            <template v-slot:default>
                <div class="social-items">
                    <a v-for="(item, index) in socialMedia"
                       :key="index"
                       :href="item.href"
                       :class="item.type">
                        <img :src="item.src" alt="">
                    </a>
                </div>
                <div class="share-link-wrap">
                    <button class="copy-action" @click="copyToClipboard">کپی</button>
                    <p class="share-link">{{ shareLink }}</p>
                </div>
            </template>
        </modal-base>
    </teleport>
</template>

<script>
import ModalBase from "@/components/Bases/ModalBase";

export default {
    name: "TheShareModal",
    components: {ModalBase},
    props: ['modalIsOpen'],
    emits: ['close'],
    data() {
        return {
            socialMedia:
                [
                    {
                        type: "telegram",
                        src: require('@/assets/images/icon/telegaram-icon.svg'),
                        href: "#",
                    },
                    {
                        type: "whatsapp",
                        src: require('@/assets/images/icon/whatsapp-icon.svg'),
                        href: "#"
                    }
                ],
            shareLink: "link"
        }
    },
    methods: {
        async copyToClipboard() {
            try {
                await navigator.clipboard.writeText(this.modal.shareLink)
            } catch (e) {
                console.log(e)
            }

        }
    }
}
</script>

<style lang="scss" scoped>

.share-modal:deep(.modal) {
  max-width: 386px
}

.social-items {
  display: flex;
  justify-content: center;
  margin-bottom: 26px;

  & > a {
    display: inline-block;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-right: 44px;

    &:first-child {
      margin-right: 0;
    }
  }
}

.share-link-wrap {
  background: #F9F9F9;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 10px 16px 11px 13px;
  margin: 0 34px 34px 34px;

  .share-link {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 29px;
    color: #FF7D0E;
  }

  .copy-action {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 27px;
    color: #000000;
    background: transparent;
    border: none;
  }
}
</style>