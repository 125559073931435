<template>
    <div class="category container">
        <div class="category-entry">
            <div v-for="category in categories" :key="category.src" class="category-item">
                <category-item :src="category.default_image" :name="category.name" :href="category.href"/>
            </div>
        </div>
    </div>
</template>

<script>
import CategoryItem from "@/components/Home/CategoryItem";


export default {
    name: "CategorySection",
    components: {CategoryItem},
    props: ['categories']
}
</script>

<style scoped lang="scss">
@import "../../scss/style";

.category {
  background: var(--light_grey_color);
  padding-bottom: 38px;
}

.category-entry {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  & > div, section {
    width: 100%;
    max-width: 100%;
  }

  justify-items: center;
  justify-content: center;

  .category-item {
    //flex: 1 0 auto;
    //max-width: 315px;
    padding: 0 8px;
    margin-bottom: 14px;
  }
}

</style>