import HomePage from "@/components/Home/HomePage";
import LoginPage from "@/components/Login/LoginPage";
import AuthPage from "@/components/Login/AuthPage";
import CategoryPage from "@/components/Category/CategoryPage";
import ProductPage from "@/components/Product/ProductPage";
import ShippingPage from "@/components/Shipping/ShippingPage";
import PanelPage from "@/components/Panel/PanelPage";
import ProfileInfoPage from "@/components/Panel/ProfileInfoPage";
import FinancialReportPage from "@/components/Panel/FinancialReportPage";
import MyOrderPage from "@/components/Panel/MyOrderPage";
import MyPackPage from "@/components/Panel/MyPackPage";
import AddressPage from "@/components/Panel/AddressPage";
import RankingPage from "@/components/Panel/RankingPage";
import FileUploadPage from "@/components/Panel/FileUploadPage";
import Test from "@/components/Test";
import AboutUsPage from "@/components/AboutUs/AboutUsPage";
import FaqPage from "@/components/Faq/FaqPage";
import ContactPage from "@/components/Contact/ContactPage";
import GalleryPage from "@/components/Gallery/GalleryPage";
import InvoicePage from "@/components/Invoice/InvoicePage";

const routes = [
    {
        name: 'home',
        path: '/',
        component: HomePage
    },
    {
        name: 'login',
        path: '/login',
        component: LoginPage
    },
    {
        name: 'auth',
        path: '/auth',
        component: AuthPage
    },
    {
        name: 'category.index',
        path: '/categories/:slug',
        component: CategoryPage
    },
    {
        name: 'product.show',
        path: '/products/:slug',
        component: ProductPage
    },
    {
        name: 'shipping',
        path: '/shipping',
        component: ShippingPage,
    },
    {
        path: '/profile',
        component: PanelPage,
        children: [
            {
                name: 'userInfo',
                path: '',
                component: ProfileInfoPage
            },
            {
                name: 'financialReport',
                path: 'financialReport',
                component: FinancialReportPage
            },
            {
                name: 'myOrders',
                path: 'myOrders',
                component: MyOrderPage
            },
            {
                name: 'myPacks',
                path: 'myPacks',
                component: MyPackPage
            },
            {
                name: 'addresses',
                path: 'addresses',
                component: AddressPage
            },
            {
                name: 'ranking',
                path: 'ranking',
                component: RankingPage
            },
            {
                name: 'fileUpload',
                path: 'fileUpload',
                component: FileUploadPage
            },
        ]
    },
    {
        path: '/aboutUs',
        name: 'aboutUs',
        component: AboutUsPage
    },
    {
        path: '/faq',
        name: 'faq',
        component: FaqPage
    },
    {
        path: '/contact',
        name: 'contact',
        component: ContactPage
    },
    {
        path: '/gallery',
        name: 'gallery',
        component: GalleryPage
    },
    {
        path: '/invoice',
        name: 'invoice',
        component: InvoicePage
    },
    {
        path: '/test',
        name: 'test',
        component: Test
    }
]

export default routes;