<template>
    <div class="address-page">
        <h6 class="main-title">نشانی ها</h6>
        <div class="address-page-entry">
            <div class="card">
                <template v-for="address in addresses" :key="address.id">
                    <div class="card-body">
                        <div class="right">
                            <div class="address">{{ address.address }}</div>
                            <div class="company">{{ address.company }}</div>
                            <div class="phone">{{ address.phone }}</div>
                            <div class="mobile">{{ address.mobile }}</div>
                            <div class="name">{{ address.name }}</div>
                        </div>
                        <div class="left">
                            <a href="" @click.prevent="modalIsOpen= true"><img
                                    :src="require('@/assets/images/icon/edit-icon.svg')"
                                    alt=""></a>
                            <a href="" @click.prevent="deleteAddress(address.id)"><img
                                    :src="require('@/assets/images/icon/delete-icon.svg')" alt=""></a>
                        </div>
                    </div>
                </template>
                <div class="card-footer">
                    <a href="" @click.prevent="modalIsOpen = true" class="add-address">
                        اضافه کردن نشانی جدید
                    </a>
                </div>
            </div>
        </div>
    </div>

    <add-address-modal :modal-is-open="modalIsOpen" @close="modalIsOpen = false"/>

</template>

<script>
import AddAddressModal from "@/components/Panel/Section/AddAddressModal";

export default {
    name: "AddressPage",
    components: {AddAddressModal},
    data() {
        return {
            modalIsOpen: false,
            addresses: [
                {
                    id: 1,
                    address: 'تهران ، جاده مخصوص کرج ، کیلومتر ۰۲ ، خیابان نفت ، پلاک ۰۲',
                    company: 'شرکت صنایع غذایی کامبیز',
                    phone: '۸۲۳۴۸۲۰۳۴',
                    mobile: '۰۹۱۲۳۴۵۷۶۵۴',
                    name: 'فرشاد احمدی',
                }, {
                    id: 2,
                    address: 'تهران ، جاده مخصوص کرج ، کیلومتر ۰۲ ، خیابان نفت ، پلاک ۰۲',
                    company: 'شرکت صنایع غذایی کامبیز',
                    phone: '۸۲۳۴۸۲۰۳۴',
                    mobile: '۰۹۱۲۳۴۵۷۶۵۴',
                    name: 'فرشاد احمدی',
                },
            ],
        }
    },
    methods: {
        deleteAddress(id) {
            this.addresses.splice(this.addresses.findIndex(i => i.id === id), 1);
        },
    }
}
</script>

<style lang="scss" scoped>
.address-page-entry {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 20px;

  > .card {
    background: #F7F7F7;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    .card-body {
      display: flex;
      justify-content: space-between;
      padding: 18px 24px 0;
      border-bottom: 1px solid rgba(65, 74, 83, 0.3);

      .address, .company, .phone, .mobile, .name {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 23px;
        text-align: right;
        color: #1E1E1E;
      }

      .company, .name {
        margin-bottom: 14px;
      }

      .phone, .mobile {
        margin-bottom: 8px;
      }

      .left {
        a {
          margin-right: 10px;
        }
      }
    }

    .card-footer {
      padding: 14px 53px 14px 0;

      .add-address {
        position: relative;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 23px;
        text-align: right;
        color: #FF7D0E;
        display: inline-block;

        &:before {
          content: "";
          background-image: url("data:image/svg+xml,%3Csvg width='15' height='20' viewBox='0 0 15 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 8.45965C15 9.43843 14.6988 10.3419 14.3224 11.2454C13.4942 13.0524 12.3648 14.7088 11.1602 16.2147C10.4825 17.1181 9.72962 17.9463 8.97671 18.7745C8.1485 19.7533 6.86856 19.7533 5.96506 18.7745C4.45924 17.0428 2.95342 15.3111 1.82405 13.3536C1.3723 12.5254 0.920545 11.6972 0.54409 10.869C-0.284113 8.9867 -0.13354 7.02913 0.769954 5.22214C1.97461 2.81282 3.93222 1.38229 6.6427 1.00583C9.80493 0.554083 13.1177 2.51165 14.3977 5.82446C14.8494 6.65266 15 7.48086 15 8.45965ZM13.5694 8.45965C13.5694 6.95383 13.1177 5.67389 12.1389 4.54452C10.9343 3.11399 9.27789 2.28579 7.39561 2.36108C4.98629 2.43638 3.2546 3.64103 2.12523 5.74919C1.5229 6.87855 1.29702 8.00791 1.59818 9.28786C1.82406 10.2666 2.2758 11.0948 2.72755 11.9983C3.93221 14.1065 5.51331 15.9888 7.09443 17.7958C7.39559 18.0969 7.69674 18.0969 7.92261 17.7958C8.60023 17.0429 9.27788 16.2899 9.88021 15.4617C11.0096 14.0312 12.0637 12.5254 12.8919 10.869C13.2683 10.1161 13.5694 9.28785 13.5694 8.45965Z'/%3E%3Cpath d='M7.92112 9.46582V11.853H7.65081V9.46582V8.96582H7.15081H4.76367V8.81096H7.15081H7.65081V8.31096V5.92383H7.92112V8.31096V8.81096H8.42112H10.8083V8.96582H8.42112H7.92112V9.46582Z' stroke='%23FF7D0E'/%3E%3C/svg%3E%0A");
          bottom: 0;
          top: 0;
          right: -30px;
          height: 20px;
          width: 15px;
          position: absolute;
          background-repeat: no-repeat;
        }
      }
    }
  }

}


</style>