<template>
    <div class="menu-dropdown">
        <div class="menu-btn">
            <svg width="28" height="26" viewBox="0 0 28 26" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M25.2903 0.354492H2.70968C1.21935 0.354492 0 1.57385 0 3.06417C0 4.55449 1.21935 5.77385 2.70968 5.77385H25.2903C26.7806 5.77385 28 4.55449 28 3.06417C28 1.57385 26.7806 0.354492 25.2903 0.354492Z"
                      fill="#414A53"/>
                <path d="M25.2903 10.29H2.70968C1.21935 10.29 0 11.5094 0 12.9997C0 14.49 1.21935 15.7094 2.70968 15.7094H25.2903C26.7806 15.7094 28 14.49 28 12.9997C28 11.5094 26.7806 10.29 25.2903 10.29Z"
                      fill="#414A53"/>
                <path d="M25.2903 20.2256H2.70968C1.21935 20.2256 0 21.4449 0 22.9353C0 24.4256 1.21935 25.6449 2.70968 25.6449H25.2903C26.7806 25.6449 28 24.4256 28 22.9353C28 21.4449 26.7806 20.2256 25.2903 20.2256Z"
                      fill="#414A53"/>
            </svg>
        </div>
        <div class="menu-dropdown-entry">
            <div class="menu-dropdown-content">
                <div class="main-category">
                    <a :href="category.href"
                       @mouseover="loadChildren(id)"
                       v-for="category in categories" :key="category.name">
                        <span class="icon" v-html="category.icon"></span>{{ category.name }}</a>
                </div>
                <div class="sub-category">
                    <div>
                        <a class="all-cat" href="#">همه دسته بندی جعبه ها ></a>
                    </div>
                    <div class="sub-category-entry">
                        <a href="#">test 1</a>
                        <a href="#">test 2</a>
                        <a href="#">test 3</a>
                        <a href="#">test 4</a>
                        <a href="#">test 5</a>
                        <a href="#">test 6</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {categories as faker_categories} from "@/faker";

export default {
    name: "MenuDropdown",
    data() {
        return {
            parent: 1,
            children: [],
            categories: []
        }
    }, created() {
        this.categories = faker_categories.filter(i => i.parent_id === 0);
    }, methods: {

    }
}
</script>

<style scoped lang="scss">
@import "../../scss/style";


.menu-btn {
  margin-left: 17px;
  cursor: pointer;
}

.menu-dropdown:hover .menu-dropdown-entry {
  display: flex;
  max-height: 500px;
  opacity: 1;
  width: 815px;

}

.menu-dropdown-entry {
  position: absolute;
  width: 0;
  right: 0;
  top: 75%;
  padding-top: 30px;
  z-index: 100;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.4s ease-out;
}

.menu-dropdown-content {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background: #FFFFFF;
  width: 100%;
  display: flex;
}

.main-category {
  flex-basis: 25%;
  border-left: 0.3px solid rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  padding-right: 17px;
  padding-top: 16px;

  a {
    padding-right: 27px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 25px;
    text-align: right;
    color: #1E1E1E;
    text-decoration: none;
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 11px;
    cursor: pointer;

    > .icon {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &:hover {
      color: #F7931E !important;
    }
  }

}

.sub-category {
  flex-basis: 75%;
  padding-top: 16px;
  padding-right: 31px;

  .all-cat {
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 21px;
    text-align: right;
    color: #000000;
    text-decoration: none;
    margin-bottom: 13px;
    display: inline-block;
  }

  .sub-category-entry {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    a {
      flex-grow: 1;
      flex-basis: 30%;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 21px;
      text-align: right;
      color: #000000;
      text-decoration: none;
      margin-bottom: 15px;

      &:hover {
        color: #F7931E;
      }
    }

  }


}


</style>