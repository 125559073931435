<template>
    <h6 class="main-title">اطلاعات حساب</h6>
    <form @click.prevent="">
        <div class="account-info">
            <div class="form-group">
                <input id="name" class="form-control" type="text" v-model="name" required>
                <label for="name">نام و نام خانوادگی</label>
            </div>
            <div class="form-group">
                <select id="gender" class="form-control" type="text" v-model="gender" required>
                    <option value="man">مرد</option>
                    <option value="woman">زن</option>
                </select>
                <label for="gender">جنسیت</label>
            </div>
            <div class="form-group">
                <input id="mobile" class="form-control" type="text" v-model="mobile" required>
                <label for="mobile">موبایل</label>
            </div>
            <div class="form-group">
                <select id="job" class="form-control" type="text" v-model="job" required>
                    <option value="0">0</option>
                    <option value="1">1</option>
                </select>
                <label for="job">شغل</label>
            </div>
            <div class="form-group">
                <input id="email" class="form-control" type="email" v-model="email" required>
                <label for="email">پست الکترونیک</label>
            </div>
            <div class="form-group">
                <input id="career" class="form-control" type="text" v-model="career" required>
                <label for="career">زمینه فعالیت</label>
            </div>
            <div class="form-group">
                <input id="tell" class="form-control" type="text" v-model="tell" required>
                <label for="tell">تلفن ثابت</label>
            </div>
            <div class="form-group">
                <input id="company" class="form-control" type="text" v-model="company" required>
                <label for="company">نام شرکت</label>
            </div>
            <div class="form-group">
                <input id="shaba" class="form-control" type="text" v-model="shaba" required>
                <label for="shaba">شماره شبا</label>
            </div>
            <div class="form-group">
                <input id="account" class="form-control" type="text" v-model="account" required>
                <label for="account">شماره حساب</label>
            </div>
        </div>
    </form>

</template>

<script>
export default {
    name: "TheProfileInfoSection",
    data() {
        return {
            name: "",
            gender: "",
            mobile: "",
            job: "",
            email: "",
            career: "",
            tell: "",
            company: "",
            shaba: "",
            account: ""
        }
    }
}
</script>

<style lang="scss" scoped>
.account-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -10px;
  margin-bottom: 24px;

  .form-group {
    flex: 0 1 auto;
    margin: 0 10px 32px;
    width: 100%;

    @media screen and (min-width: 768px) {
      width: 45%;
    }

  }

  .form-control {
    background-color: #F3F3F3;
    & ~ label {
      background-color: #F3F3F3;

      &:focus, &:valid {
        background-color: #F3F3F3;
      }
    }
  }
}

</style>