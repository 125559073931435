<template>
    <form @click.prevent="">
        <div class="legal-info-fields">
            <div class="form-group">
                <input id="organization" type="text" class="form-control" v-model="organization" required>
                <label for="organization">نام سازمان</label>
            </div>
            <div class="form-group">
                <input id="brand" type="text" class="form-control" v-model="brand" required>
                <label for="brand">برند</label>
            </div>
            <div class="form-group">
                <input id="economicCode" type="text" class="form-control" v-model="economicCode" required>
                <label for="economicCode">کد اقتصادی</label>
            </div>
            <div class="form-group">
                <input id="nationalId" type="text" class="form-control" v-model="nationalId" required>
                <label for="nationalId">شناسه ملی</label>
            </div>
            <div class="form-group">
                <input id="registrationId" type="text" class="form-control" v-model="registrationId" required>
                <label for="registrationId">شناسه ثبت</label>
            </div>
            <div class="form-group">
                <input id="tell" type="text" class="form-control" v-model="tell" required>
                <label for="tell">تلفن</label>
            </div>
            <div class="form-group">
                <select id="province" type="text" class="form-control" v-model="province" required>
                    <option value="1">تهران</option>
                </select>
                <label for="province">استان محل دفتر مرکزی</label>
            </div>
            <div class="form-group">
                <select id="city" type="text" class="form-control" v-model="city" required>
                    <option value="1">تهران</option>
                </select>
                <label for="city">شهر محل دفتر مرکزی</label>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    name: "TheLegalInfoFieldsSection",
    data() {
        return {
            organization: "",
            brand: "",
            economicCode: "",
            nationalId: "",
            registrationId: "",
            tell: "",
            province: "",
            city: "",
        }
    }
}
</script>

<style lang="scss" scoped>
.legal-info-fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -10px;

  .form-group {
    flex: 0 1 auto;
    margin: 0 10px 32px;
    width: 100%;

    @media screen and (min-width: 768px) {
      width: 45%;
    }

  }
  .form-control {
    background-color: #F3F3F3;
    & ~ label {
      background-color: #F3F3F3;

      &:focus, &:valid {
        background-color: #F3F3F3;
      }
    }
  }
}
</style>