const faker = {}


export const categories = [
    {
        id: 1,
        parent_id: 0,
        slug: 'box',
        images: [
            require("@/assets/images/BOX.png"),
            require("@/assets/images/mbox4.png"),
            require("@/assets/images/mbox3.png"),
        ],
        default_image: require("@/assets/images/BOX.png"),
        description: "در این مرحله کاربر در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های لوکس و خاص ، لیبل ، شاپینگ بگ ، کارتن لمینتی ، کارت ساده ، چاپ رول و یا شیت چاپ افست را انتخاب نمایید.در این مرحله کاربر در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ",
        name: "جعبه",
        href: "/categories/box",
        isLux: false,
        icon:`<svg width="13" height="12" viewBox="0 0 13 12" fill="red" xmlns="http://www.w3.org/2000/svg">
<path d="M8.01262 7.78648C8.01262 7.48648 8.01262 7.18648 8.01262 6.88648C8.61262 6.88648 9.21261 6.88648 9.81261 6.88648C10.7126 6.88648 11.5126 6.38646 11.6126 5.58646C11.8126 4.78646 11.3126 3.98648 10.4126 3.78648C10.1126 3.68648 10.1126 3.58648 10.1126 3.38648C10.1126 2.08648 9.21261 1.08649 7.81261 0.986488C6.81261 0.886488 5.81261 1.38647 5.31261 2.18647C5.21261 2.28647 5.11262 2.48646 5.01262 2.58646C4.91262 2.58646 4.71259 2.48649 4.61259 2.48649C3.71259 2.18649 2.81259 2.58649 2.61259 3.48649C2.61259 3.68649 2.51261 3.78648 2.31261 3.78648C1.41261 4.08648 0.912594 4.78646 1.11259 5.58646C1.31259 6.38646 2.01258 6.88648 2.91258 6.88648C3.51258 6.88648 4.11263 6.88648 4.71263 6.88648C4.71263 7.18648 4.71263 7.48648 4.71263 7.78648C3.81263 7.78648 2.91262 7.88647 2.01262 7.68647C0.912619 7.38647 0.212618 6.68646 0.0126185 5.58646C-0.0873815 4.48646 0.412606 3.68646 1.31261 3.08646C1.41261 2.98646 1.61259 2.88648 1.61259 2.78648C2.01259 1.88648 3.01259 1.28648 4.11259 1.38648C4.31259 1.38648 4.31258 1.38648 4.41258 1.28648C5.31258 0.286476 6.61262 -0.213536 8.01262 0.0864636C9.41262 0.386464 10.4126 1.28646 10.7126 2.58646C10.8126 2.88646 10.9126 3.08648 11.2126 3.28648C12.2126 3.88648 12.5126 4.98649 12.2126 5.98649C11.9126 6.98649 10.9126 7.68648 9.61259 7.78648C9.31259 7.78648 8.71262 7.78648 8.01262 7.78648Z"/>
<path d="M6.01249 9.48654C6.01249 7.98654 6.01249 6.48654 6.01249 4.98654C6.01249 4.88654 6.01249 4.78652 6.01249 4.68652C6.01249 4.38652 6.21249 4.18652 6.51249 4.18652C6.81249 4.18652 7.01249 4.38652 7.01249 4.68652C7.01249 4.78652 7.01249 4.88654 7.01249 4.98654C7.01249 6.38654 7.01249 7.78652 7.01249 9.18652C7.01249 9.28652 7.01249 9.38652 7.01249 9.58652C7.31249 9.28652 7.51248 9.08653 7.81248 8.78653C7.91248 8.68653 8.01246 8.58654 8.11246 8.48654C8.41246 8.18654 8.61245 8.18654 8.91245 8.48654C9.11245 8.68654 9.11245 8.98653 8.91245 9.28653C8.41245 9.88653 7.8125 10.3865 7.2125 10.9865C7.1125 11.0865 7.01245 11.1865 6.91245 11.2865C6.71245 11.4865 6.4125 11.4865 6.2125 11.2865C5.5125 10.5865 4.8125 9.98653 4.2125 9.28653C4.0125 9.08653 4.0125 8.78652 4.2125 8.58652C4.4125 8.38652 4.71245 8.38652 4.91245 8.58652C5.21245 8.78652 5.51249 9.18654 6.01249 9.48654C5.91249 9.58654 5.91249 9.48654 6.01249 9.48654Z" fill="#F7931E"/>
</svg>`
    }, {
        id: 2,
        parent_id: 0,
        slug: 'box_lux',

        images: [
            require("@/assets/images/BOX_LUX.png"),
            require("@/assets/images/mbox4.png"),
            require("@/assets/images/mbox3.png"),
        ],
        default_image: require("@/assets/images/BOX_LUX.png"),
        description: "در این مرحله کاربر در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های لوکس و خاص ، لیبل ، شاپینگ بگ ، کارتن لمینتی ، کارت ساده ، چاپ رول و یا شیت چاپ افست را انتخاب نمایید.در این مرحله کاربر در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ",
        name: "جعبه لوکس",
        href: "/categories/box_lux",
        isLux: true,
        icon:`<svg width="13" height="12" viewBox="0 0 13 12" fill="red" xmlns="http://www.w3.org/2000/svg">
<path d="M8.01262 7.78648C8.01262 7.48648 8.01262 7.18648 8.01262 6.88648C8.61262 6.88648 9.21261 6.88648 9.81261 6.88648C10.7126 6.88648 11.5126 6.38646 11.6126 5.58646C11.8126 4.78646 11.3126 3.98648 10.4126 3.78648C10.1126 3.68648 10.1126 3.58648 10.1126 3.38648C10.1126 2.08648 9.21261 1.08649 7.81261 0.986488C6.81261 0.886488 5.81261 1.38647 5.31261 2.18647C5.21261 2.28647 5.11262 2.48646 5.01262 2.58646C4.91262 2.58646 4.71259 2.48649 4.61259 2.48649C3.71259 2.18649 2.81259 2.58649 2.61259 3.48649C2.61259 3.68649 2.51261 3.78648 2.31261 3.78648C1.41261 4.08648 0.912594 4.78646 1.11259 5.58646C1.31259 6.38646 2.01258 6.88648 2.91258 6.88648C3.51258 6.88648 4.11263 6.88648 4.71263 6.88648C4.71263 7.18648 4.71263 7.48648 4.71263 7.78648C3.81263 7.78648 2.91262 7.88647 2.01262 7.68647C0.912619 7.38647 0.212618 6.68646 0.0126185 5.58646C-0.0873815 4.48646 0.412606 3.68646 1.31261 3.08646C1.41261 2.98646 1.61259 2.88648 1.61259 2.78648C2.01259 1.88648 3.01259 1.28648 4.11259 1.38648C4.31259 1.38648 4.31258 1.38648 4.41258 1.28648C5.31258 0.286476 6.61262 -0.213536 8.01262 0.0864636C9.41262 0.386464 10.4126 1.28646 10.7126 2.58646C10.8126 2.88646 10.9126 3.08648 11.2126 3.28648C12.2126 3.88648 12.5126 4.98649 12.2126 5.98649C11.9126 6.98649 10.9126 7.68648 9.61259 7.78648C9.31259 7.78648 8.71262 7.78648 8.01262 7.78648Z"/>
<path d="M6.01249 9.48654C6.01249 7.98654 6.01249 6.48654 6.01249 4.98654C6.01249 4.88654 6.01249 4.78652 6.01249 4.68652C6.01249 4.38652 6.21249 4.18652 6.51249 4.18652C6.81249 4.18652 7.01249 4.38652 7.01249 4.68652C7.01249 4.78652 7.01249 4.88654 7.01249 4.98654C7.01249 6.38654 7.01249 7.78652 7.01249 9.18652C7.01249 9.28652 7.01249 9.38652 7.01249 9.58652C7.31249 9.28652 7.51248 9.08653 7.81248 8.78653C7.91248 8.68653 8.01246 8.58654 8.11246 8.48654C8.41246 8.18654 8.61245 8.18654 8.91245 8.48654C9.11245 8.68654 9.11245 8.98653 8.91245 9.28653C8.41245 9.88653 7.8125 10.3865 7.2125 10.9865C7.1125 11.0865 7.01245 11.1865 6.91245 11.2865C6.71245 11.4865 6.4125 11.4865 6.2125 11.2865C5.5125 10.5865 4.8125 9.98653 4.2125 9.28653C4.0125 9.08653 4.0125 8.78652 4.2125 8.58652C4.4125 8.38652 4.71245 8.38652 4.91245 8.58652C5.21245 8.78652 5.51249 9.18654 6.01249 9.48654C5.91249 9.58654 5.91249 9.48654 6.01249 9.48654Z" fill="#F7931E"/>
</svg>`
    }, {
        id: 3,
        parent_id: 0,
        slug: 'label',
        images: [
            require("@/assets/images/LEBAL.png"),
            require("@/assets/images/mbox4.png"),
            require("@/assets/images/mbox3.png"),
        ],
        default_image: require("@/assets/images/LEBAL.png"),
        description: "در این مرحله کاربر در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های لوکس و خاص ، لیبل ، شاپینگ بگ ، کارتن لمینتی ، کارت ساده ، چاپ رول و یا شیت چاپ افست را انتخاب نمایید.در این مرحله کاربر در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ",
        name: "لیبل",
        href: "/categories/label",
        isLux: false,
        icon:`<svg width="13" height="12" viewBox="0 0 13 12" fill="red" xmlns="http://www.w3.org/2000/svg">
<path d="M8.01262 7.78648C8.01262 7.48648 8.01262 7.18648 8.01262 6.88648C8.61262 6.88648 9.21261 6.88648 9.81261 6.88648C10.7126 6.88648 11.5126 6.38646 11.6126 5.58646C11.8126 4.78646 11.3126 3.98648 10.4126 3.78648C10.1126 3.68648 10.1126 3.58648 10.1126 3.38648C10.1126 2.08648 9.21261 1.08649 7.81261 0.986488C6.81261 0.886488 5.81261 1.38647 5.31261 2.18647C5.21261 2.28647 5.11262 2.48646 5.01262 2.58646C4.91262 2.58646 4.71259 2.48649 4.61259 2.48649C3.71259 2.18649 2.81259 2.58649 2.61259 3.48649C2.61259 3.68649 2.51261 3.78648 2.31261 3.78648C1.41261 4.08648 0.912594 4.78646 1.11259 5.58646C1.31259 6.38646 2.01258 6.88648 2.91258 6.88648C3.51258 6.88648 4.11263 6.88648 4.71263 6.88648C4.71263 7.18648 4.71263 7.48648 4.71263 7.78648C3.81263 7.78648 2.91262 7.88647 2.01262 7.68647C0.912619 7.38647 0.212618 6.68646 0.0126185 5.58646C-0.0873815 4.48646 0.412606 3.68646 1.31261 3.08646C1.41261 2.98646 1.61259 2.88648 1.61259 2.78648C2.01259 1.88648 3.01259 1.28648 4.11259 1.38648C4.31259 1.38648 4.31258 1.38648 4.41258 1.28648C5.31258 0.286476 6.61262 -0.213536 8.01262 0.0864636C9.41262 0.386464 10.4126 1.28646 10.7126 2.58646C10.8126 2.88646 10.9126 3.08648 11.2126 3.28648C12.2126 3.88648 12.5126 4.98649 12.2126 5.98649C11.9126 6.98649 10.9126 7.68648 9.61259 7.78648C9.31259 7.78648 8.71262 7.78648 8.01262 7.78648Z"/>
<path d="M6.01249 9.48654C6.01249 7.98654 6.01249 6.48654 6.01249 4.98654C6.01249 4.88654 6.01249 4.78652 6.01249 4.68652C6.01249 4.38652 6.21249 4.18652 6.51249 4.18652C6.81249 4.18652 7.01249 4.38652 7.01249 4.68652C7.01249 4.78652 7.01249 4.88654 7.01249 4.98654C7.01249 6.38654 7.01249 7.78652 7.01249 9.18652C7.01249 9.28652 7.01249 9.38652 7.01249 9.58652C7.31249 9.28652 7.51248 9.08653 7.81248 8.78653C7.91248 8.68653 8.01246 8.58654 8.11246 8.48654C8.41246 8.18654 8.61245 8.18654 8.91245 8.48654C9.11245 8.68654 9.11245 8.98653 8.91245 9.28653C8.41245 9.88653 7.8125 10.3865 7.2125 10.9865C7.1125 11.0865 7.01245 11.1865 6.91245 11.2865C6.71245 11.4865 6.4125 11.4865 6.2125 11.2865C5.5125 10.5865 4.8125 9.98653 4.2125 9.28653C4.0125 9.08653 4.0125 8.78652 4.2125 8.58652C4.4125 8.38652 4.71245 8.38652 4.91245 8.58652C5.21245 8.78652 5.51249 9.18654 6.01249 9.48654C5.91249 9.58654 5.91249 9.48654 6.01249 9.48654Z" fill="#F7931E"/>
</svg>`
    }, {
        id: 4,
        parent_id: 0,
        slug: 'shopping_bag',

        images: [
            require("@/assets/images/SHOPING_BAG.png"),
            require("@/assets/images/mbox4.png"),
            require("@/assets/images/mbox3.png"),
        ],
        default_image: require("@/assets/images/SHOPING_BAG.png"),
        description: "در این مرحله کاربر در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های لوکس و خاص ، لیبل ، شاپینگ بگ ، کارتن لمینتی ، کارت ساده ، چاپ رول و یا شیت چاپ افست را انتخاب نمایید.در این مرحله کاربر در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ",
        name: "شاپینگ بگ",
        href: "/categories/shopping_bag",
        isLux: false,
        icon:`<svg width="13" height="12" viewBox="0 0 13 12" fill="red" xmlns="http://www.w3.org/2000/svg">
<path d="M8.01262 7.78648C8.01262 7.48648 8.01262 7.18648 8.01262 6.88648C8.61262 6.88648 9.21261 6.88648 9.81261 6.88648C10.7126 6.88648 11.5126 6.38646 11.6126 5.58646C11.8126 4.78646 11.3126 3.98648 10.4126 3.78648C10.1126 3.68648 10.1126 3.58648 10.1126 3.38648C10.1126 2.08648 9.21261 1.08649 7.81261 0.986488C6.81261 0.886488 5.81261 1.38647 5.31261 2.18647C5.21261 2.28647 5.11262 2.48646 5.01262 2.58646C4.91262 2.58646 4.71259 2.48649 4.61259 2.48649C3.71259 2.18649 2.81259 2.58649 2.61259 3.48649C2.61259 3.68649 2.51261 3.78648 2.31261 3.78648C1.41261 4.08648 0.912594 4.78646 1.11259 5.58646C1.31259 6.38646 2.01258 6.88648 2.91258 6.88648C3.51258 6.88648 4.11263 6.88648 4.71263 6.88648C4.71263 7.18648 4.71263 7.48648 4.71263 7.78648C3.81263 7.78648 2.91262 7.88647 2.01262 7.68647C0.912619 7.38647 0.212618 6.68646 0.0126185 5.58646C-0.0873815 4.48646 0.412606 3.68646 1.31261 3.08646C1.41261 2.98646 1.61259 2.88648 1.61259 2.78648C2.01259 1.88648 3.01259 1.28648 4.11259 1.38648C4.31259 1.38648 4.31258 1.38648 4.41258 1.28648C5.31258 0.286476 6.61262 -0.213536 8.01262 0.0864636C9.41262 0.386464 10.4126 1.28646 10.7126 2.58646C10.8126 2.88646 10.9126 3.08648 11.2126 3.28648C12.2126 3.88648 12.5126 4.98649 12.2126 5.98649C11.9126 6.98649 10.9126 7.68648 9.61259 7.78648C9.31259 7.78648 8.71262 7.78648 8.01262 7.78648Z"/>
<path d="M6.01249 9.48654C6.01249 7.98654 6.01249 6.48654 6.01249 4.98654C6.01249 4.88654 6.01249 4.78652 6.01249 4.68652C6.01249 4.38652 6.21249 4.18652 6.51249 4.18652C6.81249 4.18652 7.01249 4.38652 7.01249 4.68652C7.01249 4.78652 7.01249 4.88654 7.01249 4.98654C7.01249 6.38654 7.01249 7.78652 7.01249 9.18652C7.01249 9.28652 7.01249 9.38652 7.01249 9.58652C7.31249 9.28652 7.51248 9.08653 7.81248 8.78653C7.91248 8.68653 8.01246 8.58654 8.11246 8.48654C8.41246 8.18654 8.61245 8.18654 8.91245 8.48654C9.11245 8.68654 9.11245 8.98653 8.91245 9.28653C8.41245 9.88653 7.8125 10.3865 7.2125 10.9865C7.1125 11.0865 7.01245 11.1865 6.91245 11.2865C6.71245 11.4865 6.4125 11.4865 6.2125 11.2865C5.5125 10.5865 4.8125 9.98653 4.2125 9.28653C4.0125 9.08653 4.0125 8.78652 4.2125 8.58652C4.4125 8.38652 4.71245 8.38652 4.91245 8.58652C5.21245 8.78652 5.51249 9.18654 6.01249 9.48654C5.91249 9.58654 5.91249 9.48654 6.01249 9.48654Z" fill="#F7931E"/>
</svg>`
    }, {
        id: 5,
        parent_id: 0,
        slug: 'laminet_carton',

        images: [
            require("@/assets/images/Laminet_carton.png"),
            require("@/assets/images/mbox4.png"),
            require("@/assets/images/mbox3.png"),
        ],
        default_image: require("@/assets/images/Laminet_carton.png"),
        description: "در این مرحله کاربر در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های لوکس و خاص ، لیبل ، شاپینگ بگ ، کارتن لمینتی ، کارت ساده ، چاپ رول و یا شیت چاپ افست را انتخاب نمایید.در این مرحله کاربر در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ",
        name: "کارتن لمینتی",
        href: "/categories/laminet_carton",
        isLux: false,
        icon:`<svg width="13" height="12" viewBox="0 0 13 12" fill="red" xmlns="http://www.w3.org/2000/svg">
<path d="M8.01262 7.78648C8.01262 7.48648 8.01262 7.18648 8.01262 6.88648C8.61262 6.88648 9.21261 6.88648 9.81261 6.88648C10.7126 6.88648 11.5126 6.38646 11.6126 5.58646C11.8126 4.78646 11.3126 3.98648 10.4126 3.78648C10.1126 3.68648 10.1126 3.58648 10.1126 3.38648C10.1126 2.08648 9.21261 1.08649 7.81261 0.986488C6.81261 0.886488 5.81261 1.38647 5.31261 2.18647C5.21261 2.28647 5.11262 2.48646 5.01262 2.58646C4.91262 2.58646 4.71259 2.48649 4.61259 2.48649C3.71259 2.18649 2.81259 2.58649 2.61259 3.48649C2.61259 3.68649 2.51261 3.78648 2.31261 3.78648C1.41261 4.08648 0.912594 4.78646 1.11259 5.58646C1.31259 6.38646 2.01258 6.88648 2.91258 6.88648C3.51258 6.88648 4.11263 6.88648 4.71263 6.88648C4.71263 7.18648 4.71263 7.48648 4.71263 7.78648C3.81263 7.78648 2.91262 7.88647 2.01262 7.68647C0.912619 7.38647 0.212618 6.68646 0.0126185 5.58646C-0.0873815 4.48646 0.412606 3.68646 1.31261 3.08646C1.41261 2.98646 1.61259 2.88648 1.61259 2.78648C2.01259 1.88648 3.01259 1.28648 4.11259 1.38648C4.31259 1.38648 4.31258 1.38648 4.41258 1.28648C5.31258 0.286476 6.61262 -0.213536 8.01262 0.0864636C9.41262 0.386464 10.4126 1.28646 10.7126 2.58646C10.8126 2.88646 10.9126 3.08648 11.2126 3.28648C12.2126 3.88648 12.5126 4.98649 12.2126 5.98649C11.9126 6.98649 10.9126 7.68648 9.61259 7.78648C9.31259 7.78648 8.71262 7.78648 8.01262 7.78648Z"/>
<path d="M6.01249 9.48654C6.01249 7.98654 6.01249 6.48654 6.01249 4.98654C6.01249 4.88654 6.01249 4.78652 6.01249 4.68652C6.01249 4.38652 6.21249 4.18652 6.51249 4.18652C6.81249 4.18652 7.01249 4.38652 7.01249 4.68652C7.01249 4.78652 7.01249 4.88654 7.01249 4.98654C7.01249 6.38654 7.01249 7.78652 7.01249 9.18652C7.01249 9.28652 7.01249 9.38652 7.01249 9.58652C7.31249 9.28652 7.51248 9.08653 7.81248 8.78653C7.91248 8.68653 8.01246 8.58654 8.11246 8.48654C8.41246 8.18654 8.61245 8.18654 8.91245 8.48654C9.11245 8.68654 9.11245 8.98653 8.91245 9.28653C8.41245 9.88653 7.8125 10.3865 7.2125 10.9865C7.1125 11.0865 7.01245 11.1865 6.91245 11.2865C6.71245 11.4865 6.4125 11.4865 6.2125 11.2865C5.5125 10.5865 4.8125 9.98653 4.2125 9.28653C4.0125 9.08653 4.0125 8.78652 4.2125 8.58652C4.4125 8.38652 4.71245 8.38652 4.91245 8.58652C5.21245 8.78652 5.51249 9.18654 6.01249 9.48654C5.91249 9.58654 5.91249 9.48654 6.01249 9.48654Z" fill="#F7931E"/>
</svg>`
    }, {
        id: 6,
        parent_id: 0,
        slug: 'carton',

        images: [
            require("@/assets/images/Carton.png"),
            require("@/assets/images/mbox4.png"),
            require("@/assets/images/mbox3.png"),
        ],
        default_image: require("@/assets/images/Carton.png"),
        description: "در این مرحله کاربر در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های لوکس و خاص ، لیبل ، شاپینگ بگ ، کارتن لمینتی ، کارت ساده ، چاپ رول و یا شیت چاپ افست را انتخاب نمایید.در این مرحله کاربر در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ",
        name: "کارتن",
        href: "/categories/carton",
        isLux: false,
        icon:`<svg width="13" height="12" viewBox="0 0 13 12" fill="red" xmlns="http://www.w3.org/2000/svg">
<path d="M8.01262 7.78648C8.01262 7.48648 8.01262 7.18648 8.01262 6.88648C8.61262 6.88648 9.21261 6.88648 9.81261 6.88648C10.7126 6.88648 11.5126 6.38646 11.6126 5.58646C11.8126 4.78646 11.3126 3.98648 10.4126 3.78648C10.1126 3.68648 10.1126 3.58648 10.1126 3.38648C10.1126 2.08648 9.21261 1.08649 7.81261 0.986488C6.81261 0.886488 5.81261 1.38647 5.31261 2.18647C5.21261 2.28647 5.11262 2.48646 5.01262 2.58646C4.91262 2.58646 4.71259 2.48649 4.61259 2.48649C3.71259 2.18649 2.81259 2.58649 2.61259 3.48649C2.61259 3.68649 2.51261 3.78648 2.31261 3.78648C1.41261 4.08648 0.912594 4.78646 1.11259 5.58646C1.31259 6.38646 2.01258 6.88648 2.91258 6.88648C3.51258 6.88648 4.11263 6.88648 4.71263 6.88648C4.71263 7.18648 4.71263 7.48648 4.71263 7.78648C3.81263 7.78648 2.91262 7.88647 2.01262 7.68647C0.912619 7.38647 0.212618 6.68646 0.0126185 5.58646C-0.0873815 4.48646 0.412606 3.68646 1.31261 3.08646C1.41261 2.98646 1.61259 2.88648 1.61259 2.78648C2.01259 1.88648 3.01259 1.28648 4.11259 1.38648C4.31259 1.38648 4.31258 1.38648 4.41258 1.28648C5.31258 0.286476 6.61262 -0.213536 8.01262 0.0864636C9.41262 0.386464 10.4126 1.28646 10.7126 2.58646C10.8126 2.88646 10.9126 3.08648 11.2126 3.28648C12.2126 3.88648 12.5126 4.98649 12.2126 5.98649C11.9126 6.98649 10.9126 7.68648 9.61259 7.78648C9.31259 7.78648 8.71262 7.78648 8.01262 7.78648Z"/>
<path d="M6.01249 9.48654C6.01249 7.98654 6.01249 6.48654 6.01249 4.98654C6.01249 4.88654 6.01249 4.78652 6.01249 4.68652C6.01249 4.38652 6.21249 4.18652 6.51249 4.18652C6.81249 4.18652 7.01249 4.38652 7.01249 4.68652C7.01249 4.78652 7.01249 4.88654 7.01249 4.98654C7.01249 6.38654 7.01249 7.78652 7.01249 9.18652C7.01249 9.28652 7.01249 9.38652 7.01249 9.58652C7.31249 9.28652 7.51248 9.08653 7.81248 8.78653C7.91248 8.68653 8.01246 8.58654 8.11246 8.48654C8.41246 8.18654 8.61245 8.18654 8.91245 8.48654C9.11245 8.68654 9.11245 8.98653 8.91245 9.28653C8.41245 9.88653 7.8125 10.3865 7.2125 10.9865C7.1125 11.0865 7.01245 11.1865 6.91245 11.2865C6.71245 11.4865 6.4125 11.4865 6.2125 11.2865C5.5125 10.5865 4.8125 9.98653 4.2125 9.28653C4.0125 9.08653 4.0125 8.78652 4.2125 8.58652C4.4125 8.38652 4.71245 8.38652 4.91245 8.58652C5.21245 8.78652 5.51249 9.18654 6.01249 9.48654C5.91249 9.58654 5.91249 9.48654 6.01249 9.48654Z" fill="#F7931E"/>
</svg>`
    }, {
        id: 7,
        parent_id: 0,
        images: [
            require("@/assets/images/Rotograver.png"),
            require("@/assets/images/mbox4.png"),
            require("@/assets/images/mbox3.png"),
        ],
        default_image: require("@/assets/images/Rotograver.png"),
        slug: 'rotograver',
        description: "در این مرحله کاربر در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های لوکس و خاص ، لیبل ، شاپینگ بگ ، کارتن لمینتی ، کارت ساده ، چاپ رول و یا شیت چاپ افست را انتخاب نمایید.در این مرحله کاربر در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ",
        name: "چاپ رول",
        href: "/categories/rotograver",
        isLux: false,
        icon:`<svg width="13" height="12" viewBox="0 0 13 12" fill="red" xmlns="http://www.w3.org/2000/svg">
<path d="M8.01262 7.78648C8.01262 7.48648 8.01262 7.18648 8.01262 6.88648C8.61262 6.88648 9.21261 6.88648 9.81261 6.88648C10.7126 6.88648 11.5126 6.38646 11.6126 5.58646C11.8126 4.78646 11.3126 3.98648 10.4126 3.78648C10.1126 3.68648 10.1126 3.58648 10.1126 3.38648C10.1126 2.08648 9.21261 1.08649 7.81261 0.986488C6.81261 0.886488 5.81261 1.38647 5.31261 2.18647C5.21261 2.28647 5.11262 2.48646 5.01262 2.58646C4.91262 2.58646 4.71259 2.48649 4.61259 2.48649C3.71259 2.18649 2.81259 2.58649 2.61259 3.48649C2.61259 3.68649 2.51261 3.78648 2.31261 3.78648C1.41261 4.08648 0.912594 4.78646 1.11259 5.58646C1.31259 6.38646 2.01258 6.88648 2.91258 6.88648C3.51258 6.88648 4.11263 6.88648 4.71263 6.88648C4.71263 7.18648 4.71263 7.48648 4.71263 7.78648C3.81263 7.78648 2.91262 7.88647 2.01262 7.68647C0.912619 7.38647 0.212618 6.68646 0.0126185 5.58646C-0.0873815 4.48646 0.412606 3.68646 1.31261 3.08646C1.41261 2.98646 1.61259 2.88648 1.61259 2.78648C2.01259 1.88648 3.01259 1.28648 4.11259 1.38648C4.31259 1.38648 4.31258 1.38648 4.41258 1.28648C5.31258 0.286476 6.61262 -0.213536 8.01262 0.0864636C9.41262 0.386464 10.4126 1.28646 10.7126 2.58646C10.8126 2.88646 10.9126 3.08648 11.2126 3.28648C12.2126 3.88648 12.5126 4.98649 12.2126 5.98649C11.9126 6.98649 10.9126 7.68648 9.61259 7.78648C9.31259 7.78648 8.71262 7.78648 8.01262 7.78648Z"/>
<path d="M6.01249 9.48654C6.01249 7.98654 6.01249 6.48654 6.01249 4.98654C6.01249 4.88654 6.01249 4.78652 6.01249 4.68652C6.01249 4.38652 6.21249 4.18652 6.51249 4.18652C6.81249 4.18652 7.01249 4.38652 7.01249 4.68652C7.01249 4.78652 7.01249 4.88654 7.01249 4.98654C7.01249 6.38654 7.01249 7.78652 7.01249 9.18652C7.01249 9.28652 7.01249 9.38652 7.01249 9.58652C7.31249 9.28652 7.51248 9.08653 7.81248 8.78653C7.91248 8.68653 8.01246 8.58654 8.11246 8.48654C8.41246 8.18654 8.61245 8.18654 8.91245 8.48654C9.11245 8.68654 9.11245 8.98653 8.91245 9.28653C8.41245 9.88653 7.8125 10.3865 7.2125 10.9865C7.1125 11.0865 7.01245 11.1865 6.91245 11.2865C6.71245 11.4865 6.4125 11.4865 6.2125 11.2865C5.5125 10.5865 4.8125 9.98653 4.2125 9.28653C4.0125 9.08653 4.0125 8.78652 4.2125 8.58652C4.4125 8.38652 4.71245 8.38652 4.91245 8.58652C5.21245 8.78652 5.51249 9.18654 6.01249 9.48654C5.91249 9.58654 5.91249 9.48654 6.01249 9.48654Z" fill="#F7931E"/>
</svg>`
    }, {
        id: 8,
        parent_id: 0,
        slug: 'offset',
        images: [
            require("@/assets/images/Offset.png"),
            require("@/assets/images/mbox4.png"),
            require("@/assets/images/mbox3.png"),
        ],
        default_image: require("@/assets/images/Offset.png"),
        description: "در این مرحله کاربر در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های لوکس و خاص ، لیبل ، شاپینگ بگ ، کارتن لمینتی ، کارت ساده ، چاپ رول و یا شیت چاپ افست را انتخاب نمایید.در این مرحله کاربر در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های در صفحه اصلی با انتخاب گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ، جعبه های گروه کالایی جعبه ",
        name: "شیت افست",
        href: "/categories/offset",
        isLux: false,
        icon:`<svg width="13" height="12" viewBox="0 0 13 12" fill="red" xmlns="http://www.w3.org/2000/svg">
<path d="M8.01262 7.78648C8.01262 7.48648 8.01262 7.18648 8.01262 6.88648C8.61262 6.88648 9.21261 6.88648 9.81261 6.88648C10.7126 6.88648 11.5126 6.38646 11.6126 5.58646C11.8126 4.78646 11.3126 3.98648 10.4126 3.78648C10.1126 3.68648 10.1126 3.58648 10.1126 3.38648C10.1126 2.08648 9.21261 1.08649 7.81261 0.986488C6.81261 0.886488 5.81261 1.38647 5.31261 2.18647C5.21261 2.28647 5.11262 2.48646 5.01262 2.58646C4.91262 2.58646 4.71259 2.48649 4.61259 2.48649C3.71259 2.18649 2.81259 2.58649 2.61259 3.48649C2.61259 3.68649 2.51261 3.78648 2.31261 3.78648C1.41261 4.08648 0.912594 4.78646 1.11259 5.58646C1.31259 6.38646 2.01258 6.88648 2.91258 6.88648C3.51258 6.88648 4.11263 6.88648 4.71263 6.88648C4.71263 7.18648 4.71263 7.48648 4.71263 7.78648C3.81263 7.78648 2.91262 7.88647 2.01262 7.68647C0.912619 7.38647 0.212618 6.68646 0.0126185 5.58646C-0.0873815 4.48646 0.412606 3.68646 1.31261 3.08646C1.41261 2.98646 1.61259 2.88648 1.61259 2.78648C2.01259 1.88648 3.01259 1.28648 4.11259 1.38648C4.31259 1.38648 4.31258 1.38648 4.41258 1.28648C5.31258 0.286476 6.61262 -0.213536 8.01262 0.0864636C9.41262 0.386464 10.4126 1.28646 10.7126 2.58646C10.8126 2.88646 10.9126 3.08648 11.2126 3.28648C12.2126 3.88648 12.5126 4.98649 12.2126 5.98649C11.9126 6.98649 10.9126 7.68648 9.61259 7.78648C9.31259 7.78648 8.71262 7.78648 8.01262 7.78648Z"/>
<path d="M6.01249 9.48654C6.01249 7.98654 6.01249 6.48654 6.01249 4.98654C6.01249 4.88654 6.01249 4.78652 6.01249 4.68652C6.01249 4.38652 6.21249 4.18652 6.51249 4.18652C6.81249 4.18652 7.01249 4.38652 7.01249 4.68652C7.01249 4.78652 7.01249 4.88654 7.01249 4.98654C7.01249 6.38654 7.01249 7.78652 7.01249 9.18652C7.01249 9.28652 7.01249 9.38652 7.01249 9.58652C7.31249 9.28652 7.51248 9.08653 7.81248 8.78653C7.91248 8.68653 8.01246 8.58654 8.11246 8.48654C8.41246 8.18654 8.61245 8.18654 8.91245 8.48654C9.11245 8.68654 9.11245 8.98653 8.91245 9.28653C8.41245 9.88653 7.8125 10.3865 7.2125 10.9865C7.1125 11.0865 7.01245 11.1865 6.91245 11.2865C6.71245 11.4865 6.4125 11.4865 6.2125 11.2865C5.5125 10.5865 4.8125 9.98653 4.2125 9.28653C4.0125 9.08653 4.0125 8.78652 4.2125 8.58652C4.4125 8.38652 4.71245 8.38652 4.91245 8.58652C5.21245 8.78652 5.51249 9.18654 6.01249 9.48654C5.91249 9.58654 5.91249 9.48654 6.01249 9.48654Z" fill="#F7931E"/>
</svg>`
    }, {
        id: 9,
        parent_id: 1,
        slug: 'mbox1',
        name: 'جعبه دو سر روبرو',
        href: '/products/mbox1',
        images: [
            require("@/assets/images/mbox1.png"),
            require("@/assets/images/mbox4.png"),
            require("@/assets/images/mbox3.png"),
        ],
        default_image: require("@/assets/images/mbox1.png"),
        isLux: false
    }, {
        id: 10,
        parent_id: 1,
        slug: 'mbox2',
        name: 'جعبه دو سر روبرو',
        href: '/products/mbox2',

        images: [
            require("@/assets/images/mbox2.png"),
            require("@/assets/images/mbox4.png"),
            require("@/assets/images/mbox3.png"),
        ],
        default_image: require("@/assets/images/mbox2.png"),
        isLux: false
    },
    {
        id: 11,
        parent_id: 1,
        slug: 'mbox3',
        name: 'جعبه دو سر روبرو',
        href: '/products/mbox3',

        images: [
            require("@/assets/images/mbox3.png"),
            require("@/assets/images/mbox4.png"),
            require("@/assets/images/mbox3.png"),
        ],
        default_image: require("@/assets/images/mbox3.png"),
        isLux: false
    },
    {
        id: 12,
        parent_id: 1,
        slug: 'mbox4',
        name: 'جعبه دو سر روبرو',
        href: '/products/mbox4',
        images: [
            require("@/assets/images/mbox4.png"),
            require("@/assets/images/mbox4.png"),
            require("@/assets/images/mbox3.png"),
        ],
        default_image: require("@/assets/images/mbox4.png"),
        isLux: false
    },
    {
        id: 13,
        parent_id: 2,
        slug: 'mbox_lux1',
        name: 'جعبه دو سر روبرو',
        href: '/products/mbox_lux1',
        images: [
            require("@/assets/images/mbox1.png"),
            require("@/assets/images/mbox4.png"),
            require("@/assets/images/mbox3.png"),
        ],
        default_image: require("@/assets/images/mbox1.png"),
        isLux: true
    }, {
        id: 14,
        parent_id: 2,
        slug: 'mbox_lux2',
        name: 'جعبه دو سر روبرو',
        href: '/products/mbox_lux2',

        images: [
            require("@/assets/images/mbox2.png"),
            require("@/assets/images/mbox4.png"),
            require("@/assets/images/mbox3.png"),
        ],
        default_image: require("@/assets/images/mbox2.png"),
        isLux: true
    },
    {
        id: 15,
        parent_id: 2,
        slug: 'mbox_lux3',
        name: 'جعبه دو سر روبرو',
        href: '/products/mbox_lux3',

        images: [
            require("@/assets/images/mbox3.png"),
            require("@/assets/images/mbox4.png"),
            require("@/assets/images/mbox3.png"),
        ],
        default_image: require("@/assets/images/mbox3.png"),
        isLux: true
    },
    {
        id: 16,
        parent_id: 2,
        slug: 'mbox_lux4',
        name: 'جعبه دو سر روبرو',
        href: '/products/mbox_lux4',
        images: [
            require("@/assets/images/mbox4.png"),
            require("@/assets/images/mbox4.png"),
            require("@/assets/images/mbox3.png"),
        ],
        default_image: require("@/assets/images/mbox4.png"),
        isLux: true
    }
];

export const fields = [
    {
        category_id : 9,
        fields: [
                {
                    component: 'TheDimensionSection',
                    fields: [
                        {
                            name: 'length',
                            label: 'طول',
                            type: 'number'
                        }, {
                            name: 'width',
                            label: 'عرض',
                            type: 'number'
                        }, {
                            name: 'height',
                            label: 'ارتفاع',
                            type: 'number'
                        }, {
                            name: 'spine',
                            label: 'عطف',
                            type: 'number'
                        }
                    ],
                },
                {
                    component: 'TheCountSection',
                    fields: [
                        {
                            name: 'count',
                            label: 'تیراژ',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        }
                    ],
                },
                {
                    component: 'ThePartBodyBtnSection',
                    fields: {},
                    luxable: true
                },
                {
                    component: 'TheMaterialSection',
                    fields: [
                        {
                            name: "cardboard",
                            label: 'مقوا',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                        {
                            name: "grammage",
                            label: 'گرماژ',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        }, {
                            name: "leafType",
                            label: 'نوع ورق',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ],
                        }, {
                            name: "combination",
                            label: 'ترکیب',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ],
                        }, {
                            name: "machine",
                            label: 'ماشین آلات',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ],
                        }, {
                            name: "layerCount",
                            label: 'تعداد لایه',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ],
                        },
                        // {
                        //     name: "cardboard",
                        //     label: 'مقوا',
                        //     type: 'select',
                        //     values: [
                        //         {
                        //             name: 1000,
                        //             value: 1000
                        //         }, {
                        //             name: 2000,
                        //             value: 2000
                        //         },
                        //     ]
                        // },
                    ],
                    luxable: true
                },
                {
                    component: 'ThePrintServiceSection',
                    fields: [
                        {
                            name: "frontPrint",
                            label: 'چاپ رو',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                        {
                            name: "belowPrint",
                            label: 'چاپ زیر',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                        {
                            name: "firstLayer",
                            label: 'لایه اول',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                        {
                            name: "firstThick",
                            label: 'میکرون',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                        {
                            name: "secondLayer",
                            label: 'لایه دوم',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                        {
                            name: "secondThick",
                            label: 'میکرون',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                        {
                            name: "thirdLayer",
                            label: 'لایه سوم',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                        {
                            name: "thirdThick",
                            label: 'میکرون',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },

                    ],
                    luxable: true
                },
                {
                    component: 'TheAfterPrintSection',
                    fields: [
                        {
                            name: "varnish",
                            label: 'ورنی',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                        {
                            name: "cellophane",
                            label: 'سلفون',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                        {
                            name: "uv",
                            label: 'یو وی',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                        {
                            name: "connection",
                            label: 'اتصالات',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                        {
                            name: "print",
                            label: 'چاپ',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                    ],
                    luxable: true
                },
                {
                    component: 'TheFoilBlockSection',
                    fields: [
                        {
                            name: "foilColor",
                            label: 'رنگ',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                        {
                            name: "foilLength",
                            label: 'طول',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                        {
                            name: "foilWidth",
                            label: 'عرض',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                    ],
                    luxable: true
                },
                {
                    component: 'TheComplementaryServiceSection',
                    fields: [
                        {
                            name: "letterpress",
                            label: 'برجسته',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                        {
                            name: "packing",
                            label: 'بسته بندی',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                        {
                            name: "lace",
                            label: 'بند',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                        {
                            name: "glue",
                            label: 'چسب',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                        {
                            name: "coldSil",
                            label: 'کلدسیل',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                        {
                            name: "assemble",
                            label: 'منتاژ',
                            type: 'select',
                            values: [
                                {
                                    name: 1000,
                                    value: 1000
                                }, {
                                    name: 2000,
                                    value: 2000
                                },
                            ]
                        },
                    ],
                    luxable: true
                },
                {
                    component: 'TheInvoiceSection',
                    fields:
                        [
                            {
                                name: "invoice",
                                label: 'فاکتور',
                                type: 'text'
                            },

                        ]
                },
                {component: 'TheDescriptionSection', fields: {}},
            ]
    }
]

/*export const fields = [
    {
        category_id: 1,
        fields: ['length', 'width', 'height', 'count', 'cardboard', 'grammage', 'frontPrint', 'belowPrint', 'varnish', 'cellophane', 'uv', 'foilColor', 'foilLength', 'foilWidth', 'letterpress', 'packing']
    },
    {
        category_id: 2,
        fields: ['length', 'width', 'height', 'spine', 'count', 'door', 'floor', 'tray', 'button', 'cardboard', 'grammage', 'frontPrint', 'belowPrint', 'varnish', 'cellophane', 'uv', 'letterpress']
    },
    {
        category_id: 3,
        // /carton
        fields: ['length', 'width', 'height', 'count', 'leafType', 'combination', 'print', 'connection']
    },
    {
        // shoping bag
        category_id: 4,
        fields: ['length', 'width', 'height', 'count', 'cardboard', 'grammage', 'frontPrint', 'belowPrint', 'varnish', 'cellophane', 'uv', 'foilColor', 'foilLength', 'foilWidth', 'letterpress', 'lace', 'glue']
    },
    {// packat chips
        category_id: 5,
        fields: ['length', 'width','weight','machine','layerCount','firstLayer', 'firstThick','secondLayer', 'secondThick','thirdLayer', 'thirdThick','print','coldSil', 'assemble']
    },
    {// laminate
        category_id: 6,
        fields: ['length', 'width', 'height', 'count', 'cardboard', 'grammage', 'frontPrint', 'belowPrint', 'varnish', 'cellophane', 'uv', 'foilColor', 'foilLength', 'foilWidth', 'leafType', 'combination']
    },
    {// sheet offset
        category_id: 7,
        fields: ['length', 'width', 'height', 'count', 'cardboard', 'grammage', 'frontPrint', 'belowPrint', 'varnish', 'cellophane', 'uv', 'foilColor', 'foilLength', 'foilWidth', 'leafType', 'combination']
    }, {// label
        category_id: 8,
        fields: ['length', 'width', 'count', 'cardboard', 'grammage', 'frontPrint', 'belowPrint', 'varnish', 'cellophane', 'uv', 'foilColor', 'foilLength', 'foilWidth', 'leafType', 'combination']
    },
];*/

export const profile = {
    name: "فرناز یعقوبی",
    mobile: '09101441985',
    rank: 3,
    totalSale: 647000,
    discount: 35
};


export default faker
