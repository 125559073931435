<template>
    <div class="form-section" :class="{'lux-item':luxable}">
        <h6 class="section-title">
            خدمات تکمیلی
        </h6>
        <div class="section-fields">
            <the-input v-for="item in fieldList" :key="item.name"
                       :model-value="formData[formData.partBodyName][item.name]"
                       :item="item"
                       @input="updateFormData(item.name, $event)"
            />

        </div>
    </div>
</template>

<script>
import TheInput from "@/components/Product/TheInput";

export default {
    name: "TheComplementaryServiceSection",
    components: {TheInput},
    props: ['formData', 'fieldList', 'luxable'],
    emits: ['update:formData'],
    methods: {
        updateFormData(field, e) {
            let a = this.formData
            a[a.partBodyName][field] = e
            this.$emit('update:formData', a)
        }
    }
}
</script>

<style scoped>

</style>