<template>
    <div class="form-group">
        <DatePicker class="date-control"
                    :class="{open : dateIsOpen, filled: date}"
                    v-model="date"
                    @opened="dateIsOpen = true"
                    @closed="dateIsOpen = false"/>
        <label>از تاریخ</label>
    </div>

</template>

<script>
import DatePicker from "vue3-persian-datepicker";

export default {
    name: "DatePickerBase",
    components: {
        DatePicker
    },
    props: {
        modelValue: {}
    },
    watch: {
        date(newValue) {
            this.$emit('update:modelValue', newValue);
        }
    },
    data() {
        return {
            date: this.modelValue,
            dateIsOpen: false,
        }
    },
    emits: ['update:modelValue'],
}
</script>

<style scoped>

</style>