<template>
    <div class="shipping container">
        <div class="shipping-entry">
            <form @submit.prevent="">
                <period-section/>
                <send-method-section/>
                <address-section/>
                <the-invoice-section/>
                <div class="form-btn">
                    <button type="button">تکمیل فرایند خرید</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import PeriodSection from "@/components/Shipping/Section/PeriodSection";
import SendMethodSection from "@/components/Shipping/Section/SendMethodSection";
import AddressSection from "@/components/Shipping/Section/AddressSection";
import TheInvoiceSection from "@/components/Shipping/Section/TheInvoiceSection";

export default {
    name: "ShippingPage",
    components: {TheInvoiceSection, AddressSection, SendMethodSection, PeriodSection},

}
</script>

<style lang="scss" scoped>
.shipping {

  .shipping-entry {
    max-width: 936px;
    margin: 50px 10px;
  }

  &:deep(.shipping-section) {
    margin-bottom: 12px;

    &> .card {
      background: #FFFFFF;
      border-radius: 10px;
      padding: 17px 17px 35px;

      & > .card-title {
        padding-bottom: 6px;
        margin-bottom: 25px;
        margin-right: 8px;
        margin-left: 8px;

        & > h6 {
          display: inline-block;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 31px;
          text-align: right;
          color: #000000;
          padding: 0;
          margin: 0;
          border-bottom: 2px solid var(--orange_color);
        }
      }
    }
  }

  .form-btn {
    text-align: center;
    button {
      background: #00D900;
      border-radius: 5px;
      font-weight: bold;
      font-size: 15px;
      line-height: 29px;
      text-align: right;
      color: #1E1E1E;
      padding: 10px 60px;
    }
  }

}

</style>