<template>
    <div class="user-info-wrap">
        <div class="user-info">
            <div class="name">{{ profile.name }}</div>
            <div class="rank">
                <img v-for="star in profile.rank" :key="star" :src="require('@/assets/images/icon/star-icon.svg')">
            </div>
        </div>
        <div class="mobile">
            {{ profile.mobile }}
        </div>
    </div>
</template>

<script>
import {profile as fake_profile} from "@/faker";

export default {
    name: "UserInfoSection",
    data() {
        return {
            profile: fake_profile,
        }
    }
}
</script>

<style lang="scss" scoped>

.user-info-wrap {
  background: var(--profile_card_background);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 14px 25px 38px;
  margin-bottom: 14px;

  .user-info {
    display: flex;
    justify-content: space-between;

    .name {
      font-weight: bold;
      font-size: 15px;
      line-height: 29px;
      text-align: right;
      color: var(--profile_card_text);
    }

    .mobile {
      font-weight: bold;
      font-size: 14px;
      line-height: 27px;
      text-align: right;
      color: var(--profile_card_text)
    }

  }

}

</style>