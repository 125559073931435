<template>
    <div class="customer container">
        <div>
            <main-title>مشتریان ما</main-title>
            <div class="customer-list-wrap container">
                <section>
                    <div class="customer-list">
                        <img v-for="(customer,index) in customers" :key="index" :src="customer.path" alt="">
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import MainTitle from "@/components/Bases/MainTitle";

export default {
    name: "CustomerSection",
    components: {MainTitle},
    data() {
        return {
            customers: [
                {path: require("@/assets/images/ghaflankooh.png")},
                {path: require("@/assets/images/baraka.png")},
                {path: require("@/assets/images/Ma2.png")},
                {path: require("@/assets/images/kral.png")},
                {path: require("@/assets/images/ozel.png")},
            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../scss/style";

.customer {

  >div {
    max-width: 100%;
  }

  .customer-list-wrap {
    background: var(--secondary_color);
    width: 100%;
  }

  .customer-list {
    height: 121px;
    padding-top: 15px;
    //display: flex;
    //justify-content: center;
    //align-items: center;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;

    img {
      display: inline-block;
      margin-right: 20px;
      margin-left: 20px;
    }
  }
}

</style>