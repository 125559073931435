<template>
    <div class="legal-info">
        <h6 class="main-title">اطلاعات حقوقی</h6>
        <p v-if="!filedBoxIsOpen" class="description">با افزودن اطلاعات حقوقی می توانید با خرید سازمانی ، فاکتور رسمی و
            گواهی ارزش افزوده دریافت نمایید.</p>
        <button v-if="!filedBoxIsOpen" class="edit-link" @click="filedBoxIsOpen = true">ویرایش اطلاعات حقوقی ></button>

        <transition name="fade">
            <the-legal-info-fields-section v-if="filedBoxIsOpen"/>
        </transition>
    </div>
</template>

<script>
import TheLegalInfoFieldsSection from "@/components/Panel/Section/TheLegalInfoFieldsSection";

export default {
    name: "TheLegalInfoSection",
    components: {TheLegalInfoFieldsSection},
    data() {
        return {
            filedBoxIsOpen: false
        }
    },
    methods: {}
}
</script>

<style lang="scss" scoped>

.legal-info {
  margin-bottom: 111 - 32px;

  .description {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 25px;
    text-align: right;
  }

  .edit-link {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 27px;
    text-align: right;
    color: #FF7D0E;
    border: 0;
    background-color: transparent;
    padding: 0;
    margin-bottom: 228px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }


}


</style>