<template>
    <div class="navigation-menu">
        <template v-for="(item, index) in navigationMenuItems" :key="index">
            <div class="navigation-item" :id="item.id">
                <a :href="item.href" @click.prevent="$router.push(item.href)">
                    <img :src="item.src" alt="">
                </a>
                <div v-if="item.id === 'login'">
                    <transition name="fade">
                        <div class="dropdown">
                            <router-link v-for="(item, index) in loginItems"
                                         :key="index"
                                         :to="item.href"
                            > {{ item.title }}
                            </router-link>
                        </div>
                    </transition>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "TheNavigation",
    data() {
        return {
            navigationMenuItems: [
                {
                    src: require('@/assets/images/icon/home-icon.svg'), href: "/"
                },
                {
                    src: require('@/assets/images/icon/menu-icon.svg'), href: {name:'gallery'}
                },
                {
                    src: require('@/assets/images/icon/pack-icon.svg'), href: {name:'myPacks'}
                },
                {
                    src: require('@/assets/images/icon/order-icon.svg'), href: {name: 'myOrders'}
                },
                {
                    src: require('@/assets/images/icon/profile-icon.svg'), href: "/login", id: "login"
                },
            ],
            loginItems: [
                {title: "آپلود فایل چاپی", href: {name: "fileUpload"}},
                {title: "گزارش مالی", href: {name:'financialReport'}},
                {title: "رتبه بندی شما", href: {name: "ranking"}},
                {title: "اطلاعات حساب", href: {name: "userInfo"}},
                {title: "نشانی ها", href: {name:'addresses'}},
                {title: "خروج", href: "/"},
            ]
        }
    }
}
</script>

<style scoped lang="scss">
.navigation-menu {
  display: none;
  justify-content: flex-start;
  margin-bottom: 20px;

  @media (min-width: 992px) {
    display: flex;
  }

  .navigation-item {
    margin-right: 11px;

    &:first-child {
      margin-right: 0;
    }

  }

  #login {
    position: relative;

    .dropdown {
      display:none;

      @media (min-width: 992px) {
        display: flex;
      }
        flex-direction: column;
      list-style: none;
      width: 0;
      max-height: 0;
      padding: 17px 56px 0 10px;
      background: #FFFFFF;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      position: absolute;
      left: 0;
      opacity: 0;
      overflow: hidden;
      transition: opacity 0.3s ease-out;
      a {
        margin-bottom: 18px;
      }

    }

    &:hover .dropdown {

      opacity: 1;
      width: 196px;
      max-height: 400px;
      @media (min-width: 992px) {
        display: flex;
      }
    }

  }

}


</style>