<template>
    <div class="more-product">
        <main-title>
            نمونه های بیشتر جعبه را بررسی کنید.

        </main-title>

        <div class="more-product-slider">
            <div class="slider-btn-right">
                <img :src="require('@/assets/images/icon/arrow-head-right-icon.svg')" alt="">
            </div>
            <swiper :spaceBetween="15" :slidesPerView="'auto'"
                    :navigation="{ nextEl: '.slider-btn-right', prevEl: '.slider-btn-left', }">
                <swiper-slide><img :src="require('@/assets/images/Laminet_carton.png')"/></swiper-slide>
<!--                <swiper-slide><img src="https://swiperjs.com/demos/images/nature-1.jpg"/></swiper-slide>-->
<!--                <swiper-slide><img src="https://swiperjs.com/demos/images/nature-2.jpg"/></swiper-slide>-->
<!--                <swiper-slide><img src="https://swiperjs.com/demos/images/nature-3.jpg"/></swiper-slide>-->
<!--                <swiper-slide><img src="https://swiperjs.com/demos/images/nature-4.jpg"/></swiper-slide>-->
            </swiper>
            <div class="slider-btn-left">
                <img :src="require('@/assets/images/icon/arrow-head-left-icon.svg')" alt="">
            </div>
        </div>
    </div>
</template>


<script>
import {Swiper, SwiperSlide} from 'swiper/vue';

// Import Swiper styles
import 'swiper/swiper.scss';

import MainTitle from "@/components/Bases/MainTitle";

export default {
    name: "TheMoreProductSection",
    components: {
        MainTitle,
        Swiper,
        SwiperSlide,
    },
}
</script>


<style lang="scss" scoped>

.more-product {
  margin: 0 40px;


  .more-product-slider {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .slider-btn-left {
      margin-right: 16px;
    }

    .slider-btn-right {
      margin-left: 16px;
    }


    > .swiper-container {
      display: flex;

      .swiper-wrapper {
        flex: 1 1 auto;
      }
    }

    .swiper-slide {
      height: 155px !important;
      width: 155px !important;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
      }
    }
  }

}
</style>