<template>

    <div class="container project">
        <div class="project-entry">
            <main-title>مراحل انجام پروژه</main-title>
            <div class="phases-wrap">
                <div class="phases">
                    <div class="phases-entry">
                        <template v-for="(phase, index) in phases" :key="index">
                            <div class="phase active"
                                 @mouseenter="changeIndicator(index)"
                                 @mouseleave="changeIndicator(0)"
                            >
                                <p>{{ phase }}</p>
                            </div>
                        </template>
                        <div class="indicator"
                             :style="'right:'+indicatorPosition+'px'"
                        ></div>
                    </div>
                </div>
            </div>
            <ul class="description">
                <li v-for="description in descriptions" :key="description">
                    {{ description }}
                </li>
            </ul>

        </div>
    </div>
</template>

<script>
import MainTitle from "@/components/Bases/MainTitle";

export default {
    name: "ProjectSection",
    components: {MainTitle},
    data() {
        return {
            indicatorPosition: 60,
            phases: [
                "انتخاب محصول",
                "تکمیل فرم سفارش",
                "برآورد هزینه",
                "سفارش درخواست",
                "پرداخت",
                "ارسال",
            ],
            descriptions: [
                'در این مرحله در صفحه اصلی با انتخاب گروه کالایی ( جعبه، جعبه های لوکس و خاص، لیبل، شاپینگ بگ، ارتن لمینتی، کارتن ساده، چاپ رول، و یا شیت افست) را انتخاب نمایید.',
                'در زیرمجموعه هریک از گروه های اصلی با دقت مدل مشابه بسته بندی شما قرار گرفته، کالای مورد نظر را انتخاب نمایید تا به مرحله بعد وارد شوید.',
                'برای ارتباط بیشتر و آموزش این مرحله توصیه می شود فیلم آموزشی (انتخاب کالا) را مشاهده نمایید.',
            ]
        }
    }, methods: {
        changeIndicator(index = 0) {
            this.indicatorPosition = index * 140 + 60;
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../scss/style";


.project {
  padding-bottom: 59px;
  background: var(--light_grey_color);

  .phases-wrap {
    text-align: center;
    width: 100%;
    overflow-x: scroll;
  }

  .phases {
    display: inline-block;
    width: 820px;
  }

  .phases-entry {
    display: flex;
    position: relative;
    justify-content: center;
    padding-bottom: 40px;
    border-bottom: 2.5px solid var(--secondary_color);
    margin-bottom: 24px;

    .indicator {
      content: "";
      position: absolute;
      width: 1.5px;
      height: 40px;
      background: var(--secondary_color);
      bottom: 0;
      right: 60px;
      transition: right 0.3s ease-in-out;
      transition-delay: 0.1s;
    }
  }


  .phase {
    background: var(--secondary_color);
    color: white;
    border: 1.5px solid var(--secondary_color);
    height: 120px;
    width: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;

    &:hover {
      background: white;
      color: var(--secondary_color);
    }

    &:first-child {
      margin-right: 0;
    }

    & > p {
      display: inline-block;
      line-height: 24.83px;
      margin: 0;
      padding: 0;
      font-weight: 700;
      font-style: normal;
      font-size: 13px;
    }
  }

  .description {
    list-style-image: url("../../assets/images/highlighter.svg");
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 23px;
    text-align: right;
    color: #000000;
    text-overflow: ellipsis;
  }
}


</style>