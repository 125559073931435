<template>
    <div class="service-item">
        <div class="card">
            <div class="card-body">
                <a @click.prevent="$router.push(href)" href="">
                    <h3 class="title">{{ title }}</h3>
                    <p class="description">
                        {{ descriptionFitter }}
                    </p>
                </a>
            </div>
            <div class="card-image">
                <div class="aspect11">
                    <img :src="src" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ServiceCard",
    props : ['title', 'description','src','href'],
    computed: {
        descriptionFitter() {
            const l = 200;
            return this.description.length > l ? this.description.substr(0, l) + ' ...' : this.description;
        }
    }
}
</script>

<style scoped lang="scss">

.service-item {
    //flex: 1 1 350px;
  //width: 50%;
  padding: 10px;

  @media screen and (min-width: 576px){
    flex: 0 0 auto;
    width: 50%;
  }

}


.card {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  background: rgb(243, 243, 243, 0.5);
  margin-bottom: 22px;

  @media screen and (min-width: 992px){
    flex-direction: row;
  }


  .card-body {
    flex-grow: 1;
    padding: 12px 22px 19px 27px;
    @media screen and (min-width: 992px){
     max-width: 50%;
    }

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 31px;
      text-align: right;
      color: var(--primary_color);
      margin-bottom: 10px;
    }

    .description {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 28px;
      text-align: right;
      color: #000000;
      overflow: hidden;
    }
  }

  .card-image {
    //max-width: 350px;
    width: 100%;
    @media screen and (min-width: 992px){
      max-width: 50%;
    }
  }
}

.aspect11 {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;

  img {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>