<template>

    <main class="main">
        <the-banner/>

        <category-section :categories="categories"/>

        <project-section/>

        <service-section :categories="categories"/>

        <customer-section/>
    </main>
</template>

<script>
import CategorySection from "@/components/Home/CategorySection";
import ProjectSection from "@/components/Home/ProjectSection";
import ServiceSection from "@/components/Home/ServiceSection";
import CustomerSection from "@/components/Home/CustomerSection";
import TheBanner from "@/components/Header/TheBanner";
import {categories as fake_categories} from "@/faker";

export default {
    name: "HomePage",
    components: {
        TheBanner,
        CustomerSection,
        ServiceSection,
        ProjectSection,
        CategorySection,
    },
    computed: {
        categories() {
            return fake_categories.filter(category => category.parent_id === 0)
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../scss/style.css";

.main {
  background: white;
}


</style>