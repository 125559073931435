<template>
    <main class="main">
        <the-banner/>

        <div class="category container">
            <div class="category-entry">
                <category-item
                        v-for="category in categories"
                        :key="category.title"
                        :title="category.title"
                        :src="category.default_image"
                        :href="category.href"
                />

            </div>
        </div>
    </main>
</template>

<script>
import TheBanner from "@/components/Header/TheBanner";
import CategoryItem from "@/components/Category/CategoryItem";
import {categories as faker_categories} from "@/faker";

export default {
    name: "CategoryPage",
    components: {CategoryItem, TheBanner},
    data() {
        return {
            categories: [],
        }
    },
    methods: {
       loadData(param) {
           let parent = faker_categories.find(category => category.slug === param)
           this.categories = faker_categories.filter(category => category.parent_id === parent.id);
       }
    },
    created() {
        this.loadData(this.$route.params.slug)
    }
}
</script>

<style scoped lang="scss">

.category {

  .category-entry {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 (31-9) * 1px;
    margin-bottom: (52-17) * 1px;
  }


}
</style>