<template>
    <div class="item">
        <div class="header">
            <div class="star">
                <img v-for="item in rank" :key="item" :src="require('@/assets/images/svg/ranking.svg')">
            </div>
            <p class="title">{{ title }}</p>
        </div>
        <div class="body">
            {{ description }}
        </div>
    </div>
</template>

<script>
export default {
    name: "RankingDescriptionItem",
    props:['title', 'rank', 'description']
}
</script>

<style lang="scss" scoped>
.item {
  flex: 1 1 200px;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-left: 30px;

  .star {
    margin-bottom: 23px;
  }

  .title {
    font-weight: bold;
    font-size: 15px;
    line-height: 29px;
    text-align: right;
    color: #FF7D0E;
    margin-bottom: 20px;
  }

  .body {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 25px;
    text-align: right;
    color: #1E1E1E;
  }
}
</style>