<template>
    <div class="faq-page container">
        <div class="faq-wrap">
            <div class="faq">
                <div class="title">
                    <h6>سوالات متداول</h6>
                </div>
                <div class="faq-entry">
                    <the-faq-item
                            v-for="(faq, index) in faqList" :key="index"
                            :faq="faq"
                            :number="index+1"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TheFaqItem from "@/components/Faq/TheFaqItem";

export default {
    name: "FaqPage",
    components: {TheFaqItem},
    data() {
        return {
            faqList: [
                {
                    question: "شرایط استفاده از کد تخفیف اولین خرید چیست؟",
                    answer: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، "
                },
                {
                    question: "شرایط خرید و بازگرداندن کالا برای کالاهای سوپر مارکتی چگونه است؟",
                    answer: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، "
                },
                {
                    question: "میتوانم سفارشم را بصورت اقساطی ) اعتباری ( پرداخت کنم؟",
                    answer: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، "
                },
                {
                    question: "چطور درخواست خود را جهت بازگردان کالا )مرجوعی کالا( به شما اطلاع دهم؟",
                    answer: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، "
                },
                {
                    question: "چطور میتوانم بدون برقراری تماس، درخواست مرجوعی کالای خریداری شده را اعلام کنم؟",
                    answer: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، "
                },
                {
                    question: "چطور میتوانم سفارشم را لغو کنم ؟",
                    answer: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، "
                }
            ]
        }
    }
}

</script>

<style lang="scss" scoped>
.faq-page {

  .faq {
    width: 100%;
    max-width: 1000px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 28px 46px 53px;
    margin: 70px auto 100px;
  }

  .title {
    text-align: center;
    margin-bottom: 60px;

    h6 {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 38px;
      color: #000000;
      padding-bottom: 7.5px;
      border-bottom: 1px solid var(--orange_color);
      display: inline-block;
    }
  }


}

</style>