<template>
    <div class="profile-info">
        <the-profile-info-section/>
        <the-legal-info-section/>
    </div>
</template>

<script>
import TheProfileInfoSection from "@/components/Panel/Section/TheProfileInfoSection";
import TheLegalInfoSection from "@/components/Panel/Section/TheLegalInfoSection";

export default {
    name: "ProfileInfoPage",
    components: {TheLegalInfoSection, TheProfileInfoSection},
}
</script>

<style lang="scss" scoped>

.profile-info {






}


</style>