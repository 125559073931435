<template>

    <div class="form-group" v-if="item.type !== 'select'">
        <input
                class="form-control"
                :type="item.type"
                :value="modelValue"
                :id="item.name"
                @input="$emit('input', $event.target.value)"
                required
        >
        <label :for="item.name">{{ item.label }}</label>
    </div>

    <div class="form-group" v-if="item.type === 'select'">
        <select class="form-control"
                :id="item.name"
                :value="modelValue"
                @input="$emit('input', $event.target.value)"
                required
        >
            <option v-for="i in item.values"
                    :key="i.name"
                    :value="i.value"
            >{{ i.name }}
            </option>
        </select>
        <label :for="item.name">{{ item.label }}</label>
    </div>

</template>

<script>
export default {
    name: "TheInput",
    props: ['modelValue', 'item'],
    emits: ['input']
}
</script>

<style scoped>

</style>