<template>
    <div>
        <date-picker v-model="date"></date-picker>
    </div>
</template>

<script>
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
export default {
    data(){
        return {
            date: ''
        }
    },
    components: {
        datePicker: VuePersianDatetimePicker
    }
}
</script>

<style lang="scss" scoped>


</style>