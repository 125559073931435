import {createApp} from 'vue'


import App from './App.vue'
import router from "@/Libs/vue-router/main";
require('@/assets/style/style.css');


const app = createApp(App);

app.use(router);
app.mount('#app');
