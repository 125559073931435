<template>
    <div class="upload-file-page">
        <h6 class="main-title">آپلود اسناد مالی</h6>
        <div class="uploader-section">
            <form @submit.prevent="">
                <div class="uploader-wrap">
                    <div class="uploader-field">
                        <div class="form-group">
                            <input type="text" id="orderId" v-model="orderId" class="form-control" required>
                            <label for="orderId">شماره سفارش</label>
                        </div>
                        <div class="form-group">
                            <textarea id="description" class="form-control" v-model="description" required></textarea>
                            <label for="description">شرح</label>
                        </div>
                    </div>
                    <div class="uploader">
                        <div class="uploader-entry">
                            <img :src="require('@/assets/images/svg/uploader.svg')">
                            <p>+ بارگذاری تصویر</p>
                        </div>
                    </div>
                </div>
                <p class="replacement-way">و یا از طریق تلگرام و یا واتس آپ به شماره ۵۷۳۴۵۹۸۹۰ با ذکر شماره سفارش ارسال نمایید.</p>
                <button type="button" class="uploader-btn">ارسال</button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: "FileUploadPage",

}
</script>

<style lang="scss" scoped>
.upload-file-page {
  .description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 23px;
    text-align: right;
    color: #1E1E1E;
  }

  .upload-link {
    font-weight: bold;
    font-size: 14px;
    line-height: 27px;
    text-align: right;
    color: #FF7D0E;
    border: none;
    padding: 0;
    background-color: transparent;
    margin-bottom: 232px;
  }

  .uploader-section {
    margin-bottom: 54px;

  }

  .uploader-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .uploader-field {
    flex: 1 0 250px;
    padding-right: 15px;
    padding-left: 15px;

    .form-control {
      margin-bottom: 30px;
      background-color: var(--profile_background);
    }

    label {
      background-color: var(--profile_background);
    }
  }

  .uploader {
    flex: 1 0 250px;
    padding-right: 15px;
    padding-left: 15px;

    .uploader-entry {
      background: #F2F2F2;
      border: 1px dashed rgba(30, 30, 30, 0.5);
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 28px;
      padding-bottom: 24px;
      margin-bottom: 30px;
    }

    img {
      display: inline-block;
      margin-bottom: 24px;
    }

    p {
      font-size: 13px;
      line-height: 25px;
      text-align: right;
      color: #1E1E1E;
    }
  }

  .uploader-btn {
    background: #414A53;
    border-radius: 5px;
    color: #FFFFFF;
    padding: 9px 72px;
    border: none;
    display: block;
    margin: 0 auto;
  }

  .replacement-way {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 29px;
    text-align: right;
    color: #000000;
  }

}
</style>