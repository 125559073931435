<template>
    <div class="shipping-section period">
        <div class="card">
            <div class="card-title">
                <h6>دوره چاپ</h6>
            </div>
            <div class="card-body">
                <div class="period-entry">
                    <template v-for="item in periodLoad" :key="item.id">
                        <radio-card>
                            <template v-slot:header>
                                <input :id="item.id" type="radio" class="form-check" :value="item.value" v-model="period">
                                <label :for="item.id">{{item.title}}</label>
                            </template>
                            <template v-slot:default>
                                {{ item.description }}
                                <div v-if="item.id === 'print-again'" class="form-group">
                                    <input id="orderId" type="text" v-model="orderId" class="form-control" :disabled="period !== 'print-again'">
                                    <label for="orderId">شماره سفارش قبلی</label>
                                </div>
                            </template>
                        </radio-card>

                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RadioCard from "@/components/Shipping/Section/radio-card";

export default {
    name: "PeriodSection",
    components: {RadioCard},
    data() {
        return {
            period:"",
            periodLoad: [
                {
                    title: 'برای اولین بار',
                    description: 'هزینه قالب، کلیشه، شابلون، فیلم (بنا به موارد انتخاب شده) طی فاکتور مجزا اعلام می گردد.',
                    id:'first-time',
                    value: 'first-time'
                }, {
                    title: 'تجدید چاپ',
                    description: '',
                    id:'print-again',
                    value: 'print-again'
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>


.card-body {
width: 100%;
}


.period-entry {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
}

</style>