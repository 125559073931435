<template>
    <div class="shipping-section send-method">
        <div class="card">
            <div class="card-title">
                <h6>دوره چاپ</h6>
            </div>
            <div class="card-body">
                <div class="send-method-entry">
                    <template v-for="method in methods" :key="method.title">
                        <div class="form-group">
                            <input id="sendMethod" class="form-check" type="radio" name="method" v-model="sendMethod" value="{{ method.value }}">
                            <label for="sendMethod">{{ method.title }}</label>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SendMethodSection",
    data() {
        return {
            sendMethod:"",
            methods: [
                {
                    title: 'حمل توسط سیزپک',
                    value: "sizpack"
                },
                {
                    title: 'حمل توسط خریدار',
                    value: "customer"
                }
            ]
        }
    }
}
</script>

<style scoped>
.card-body {
    margin-right: 8px;
    margin-left: 8px;
}

</style>