<template>
    <div class="shipping-section invoice">
        <div class="card">
            <div class="card-title">
                <h6>صدور فاکتور</h6>
            </div>
            <div class="card-body">
                <div class="invoice-entry">
                    <template v-for="item in customerTypes" :key="item.title">
                        <div class="form-group">
                            <input class="form-check" type="radio" :value="item.value" v-model="customerType">
                            <label :for="item.value">{{ item.title }}</label>
                        </div>
                    </template>
                </div>
            </div>
            <div class="card-title">
                <h6>شیوه پرداخت</h6>
            </div>
            <div class="card-body">
                <div class="payment-method-entry">
                    <template v-for="item in paymentMethods" :key="item.title">
                        <div class="form-group">
                            <input class="form-check" type="radio" :value="item.value" v-model="paymentMethod">
                            <label :for="item.value">{{ item.title }}</label>
                        </div>
                    </template>
                </div>
            </div>
            <div class="accounts">
                <div class="natural-accounts">
                    <h6 class="title">{{ accounts.natural.title }}</h6>
                    <ul>
                        <li>{{ accounts.natural.account.title }} {{ accounts.natural.account.number }}</li>
                        <li>{{ accounts.natural.shaba.title }} {{ accounts.natural.shaba.number }}</li>
                        <li>{{ accounts.natural.cart.title }} {{ accounts.natural.cart.number }}</li>
                    </ul>
                </div>
                <div class="legal-account">
                    <h6 class="title">{{ accounts.legal.title }}</h6>
                    <ul>
                        <li>{{ accounts.legal.account.title }} {{ accounts.legal.account.number }}</li>
                        <li>{{ accounts.legal.shaba.title }} {{ accounts.legal.shaba.number }}</li>
                        <li>{{ accounts.legal.cart.title }} {{ accounts.legal.cart.number }}</li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TheInvoiceSection",
    data() {
        return {
            customerType: "",
            paymentMethod: "",
            customerTypes: [
                {
                    title: 'خرید شخص حقیقی',
                    value: "real",
                    description: "برای خرید ، تکمیل فرم اطلاعات حساب الزامی می باشد."
                },
                {
                    title: 'خرید شخص حقوقی',
                    value: "legal",
                    description: "برای خرید سازمانی ، تکمیل فرم مشخصات سازمانی الزامی می باشد و ۹٪ ارزش افزوده به مبلغ قاکتور اضافه می گردد."
                }
            ],
            paymentMethods: [
                {
                    title: 'پرداخت الکترونیکی',
                    value: "creditcard",
                    description: "آنلاین با تمامی کارت های بانکی"
                },
                {
                    title: 'واریز به حساب',
                    value: "account",
                    description: "واریز به هریک از حساب های ذیل و آپلود فیش واریزی در گزارش مالی حساب کاربری خود"
                }
            ],
            accounts: {
                natural: {
                    title: "شماره حساب جهت خرید عادی",
                    account: {
                        title: "شماره حساب",
                        number: " ۸۷۶۲۳۴۹۸۷۹۲۶۳۴"
                    },
                    shaba: {
                        title: "شماره شبا",
                        number: "IR۸۷۶۲۳۴۹۸۷۹۰۰۰۰۰۲۶۳۴"
                    },
                    cart: {
                        title: "شماره کارت",
                        number: "۵۸۹۲ ۱۰۱۱ ۷۸۴۴ ۶۵۴۴"
                    }
                },
                legal: {
                    title: " شماره حساب جهت خرید سازمانی و رسمی",
                    account: {
                        title: "شماره حساب",
                        number: "۸۷۶۲۳۴۹۸۷۹۲۶۳۴"
                    },
                    shaba: {
                        title: "شماره شبا",
                        number: "IR۸۷۶۲۳۴۹۸۷۹۰۰۰۰۰۲۶۳۴"
                    },
                    cart: {
                        title: "شماره کارت",
                        number: "۵۸۹۲ ۱۰۱۱ ۷۸۴۴ ۶۵۴۴"
                    }
                },
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.invoice {
  margin-bottom: 30px;
}

.card-body {
  margin-bottom: 50px;
  margin-right: 8px;
  margin-left: 8px;
}

.accounts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    margin-right: 60px;
    margin-left: 60px;
  }

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 29px;
    text-align: right;
    color: #000000;
    margin-bottom: 5px;
  }

  .natural-accounts, .legal-account {
    padding-left: 10px;
  }

  ul {
    padding-right: 1.2em;
  }

  li {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 23px;
    text-align: right;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 4px;
  }
}
</style>