<template>
    <div class="shipping-section address">
        <div class="card">
            <div class="card-title">
                <h6>آدرس محل تحویل و تحویل گیرنده</h6>
                <a class="add-address" @click.prevent="modalIsOpen = true">افزودن آدرس جدید</a>
            </div>
            <div class="card-body">
                <div class="address-entry">
                    <template v-for="item in addresses" :key="item.title">
                        <radio-card>
                            <template v-slot:header>
                                <input class="form-check" type="radio" :id="item.id" :value="item.id" v-model="address">
                                <label :for="item.id">{{ item.title }}</label>
                            </template>
                            <template v-slot:default>
                                <p class="location-icon">{{ item.address }}</p>
                            </template>
                        </radio-card>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <add-address-modal :modal-is-open="modalIsOpen" @close="modalIsOpen = false" />
</template>

<script>
import RadioCard from "@/components/Shipping/Section/radio-card";
import AddAddressModal from "@/components/Panel/Section/AddAddressModal";

export default {
    name: "AddressSection",
    components: {AddAddressModal, RadioCard},
    data() {
        return {
            modalIsOpen: false,
            address: "",
            components: {RadioCard},
            addresses: [
                {
                    id: 1,
                    title: "نشانی",
                    address: "تهران - خیابان ولیعصر - خیابان سربداران - خیابان صدر - پلاک ۱۲"
                },
                {
                    id: 2,
                    title: "نشانی",
                    address: "تهران - خیابان ولیعصر - خیابان سربداران - خیابان صدر - پلاک ۱۲"
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>

.address {

  .address-entry {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
  }

  .card-title {
    display: flex;
    justify-content: space-between;

    .add-address {
      font-weight: normal;
      font-size: 13px;
      line-height: 25px;
      text-align: right;
      color: var(--orange_color);
      cursor: pointer;
    }


  }
}


</style>