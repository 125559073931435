<template>
    <transition name="fadeSidebar">
        <div v-if="sidebarIsOpen" @click.self="$emit('closeSidebar')" class="sidebar-wrap">
            <div class="sidebar">
                <section class="sidebar-header">
                    <div class="logo">

                        <a @click.prevent="$router.push('/')">
                            <img :src="require('@/assets/images/svg/sizpack.svg')" alt="">
                        </a>
                        <button class="close-btn" @click="$emit('closeSidebar')">
                            x
                        </button>
                    </div>
                    <the-navigation/>
                </section>
                <section class="sidebar-body">
                    <div class="accordion">
                        <template v-for="(category) in categories" :key="category.id">
                            <div class="accordion-box">
                                <div class="accordion-header"
                                     @click.stop="toggleMenu(category.id)"
                                     :class="{'has-child': hasChildren(category),
                                'open': isOpen(category)}"
                                >
                                    <a @click.prevent="">{{ category.name }}</a>
                                </div>
                                <transition name="fade">
                                    <div class="accordion-body" v-if="isOpen(category)">
                                        <a @click.prevent="" class="accordion-item" v-if="hasChildren(category)">همه
                                            موارد
                                            این دسته ></a>
                                        <a @click.prevent="" v-for="(subCategory) in category.children"
                                           :key="subCategory.id"
                                           class="accordion-item">
                                            {{ subCategory.name }}
                                        </a>
                                    </div>
                                </transition>
                            </div>

                        </template>
                    </div>
                </section>
                <section class="sidebar-footer">

                </section>
            </div>
        </div>
    </transition>
</template>

<script>
import TheNavigation from "@/components/Header/TheNavigation";
import {categories as fake_categories} from "@/faker";

export default {
    name: "TheSidebar",
    components: {TheNavigation},
    emits: ['closeSidebar'],
    props: ['sidebarIsOpen'],
    data() {
        return {
            categories: [],
            firstLevelId: false
        }
    },
    methods: {
        toggleMenu(index) {
            this.firstLevelId = (this.firstLevelId !== index) ? index : false;
        },
        isOpen(category) {
            return category.id === this.firstLevelId
        },
        hasChildren(category) {
            return category.children && category.children.length > 0;
        }
    },
    created() {
        let mainCategories = fake_categories.filter(category => category.parent_id === 0);
        mainCategories.forEach(function (item) {
            item.children = fake_categories.filter(i => i.parent_id === item.id)
        })
        this.categories = mainCategories
    }
}
</script>

<style scoped lang="scss">
.sidebar-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  z-index: 10;
  background-color: var(--light_black_color);
}

.sidebar {
  border: 2px solid var(--secondary_color);
  border-left: 5px solid var(--primary_color);
  background: var(--white_color);
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 285px;
  height: 100%;
  display: flex;
  padding: 10px 0 15px;

  &:deep(.navigation-menu) {
    display: flex;
    margin-bottom: 0;
    justify-content: center;
  }
}

.sidebar-header {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px 10px 10px;
  border-bottom: 1px solid var(--light_black_color);
  position: relative;

  .logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    img {
      display: inline-block;
      max-height: 50px;
    }
  }

  .close-btn {
    background-color: transparent;
    color: black;
    border: none;
    padding: 7px;
    font-size: 21px;
    line-height: 21px;
  }
}

.sidebar-body {
  flex: 1 1 auto;
  overflow-y: auto;
}

.accordion {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  .accordion-box {
    flex: 0 0 auto;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  }

  .accordion-header {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;

    &.open {
      color: var(--orange_color);
    }

    &.has-child::after {
      content: "+";
      font-size: 22px;
      line-height: 22px;
    }

    &.has-child.open::after {
      content: "-";
      color: var(--orange_color);
    }
  }

  .accordion-body {
    background-color: rgba(0, 0, 0, 0.1)
  }

  .accordion-item {
    padding: 10px 40px 10px 20px;
    font-size: 12px;
    display: inline-block;
    width: 100%;
  }
}


</style>

<style lang="scss">
.fadeSidebar-enter-active,
.fadeSidebar-leave-active {
  transition: opacity 0.2s ease;
}

.fadeSidebar-enter-from,
.fadeSidebar-leave-to {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.1s ease;
}

.fade-leave-to {
  opacity: 0;
}

</style>