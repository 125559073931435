<template>
    <div class="ranking-page">
        <div class="ranking-wrap">
            <h6 class="main-title">شرایط دریافت رتبه در سایت سیزپک</h6>
            <div class="ranking">
                <div class="ranking-item form-control-text">
                    <span class="label">کل فروش:</span>
                    <span class="content">{{ totalSale }}</span>
                    <span class="unit">ریال</span>
                </div>
                <div class="ranking-item form-control-text">
                    <span class="label">رتبه شما:</span>
                    <span class="content">
                    <img v-for="star in rank" :key="star" alt=""
                         :src="require('@/assets/images/icon/star-icon.svg')">
            </span>
                </div>
                <div class="ranking-item form-control-text">
                    <span class="label">درصد تخفیف:</span>
                    <span class="content">{{ discount }}</span>
                    <span class="unit">%</span>
                </div>

            </div>

            <div class="ranking-description">
                <RankingDescriptionItem
                        v-for="item in rankingDescription"
                        :key="item.title"
                        :title="item.title"
                        :rank="item.rank"
                        :description="item.description"
                />

            </div>
        </div>
        <div class="special-condition">
            <h6 class="main-title">
                شرایط خاص
            </h6>
            <p class="description">
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو
            </p>
        </div>

    </div>
</template>

<script>
import {profile as fake_profile} from "@/faker";
import RankingDescriptionItem from "@/components/Product/Section/RankingDescriptionItem";

export default {
    name: "RankingPage",
    components: {RankingDescriptionItem},
    data() {
        return {
            totalSale: fake_profile.totalSale,
            rank: fake_profile.rank,
            discount: fake_profile.discount,
            rankingDescription: [
                {
                    title: 'گرید',
                    rank: 1,
                    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو "
                },
                {
                    title: 'گرید',
                    rank: 2,
                    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو "
                },
                {
                    title: 'گرید',
                    rank: 3,
                    description: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو "
                },
            ],
        }
    }
}
</script>

<style lang="scss" scoped>

.ranking-page {
  .ranking-wrap {
    margin-bottom: 50px;
  }

  .ranking {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-left: -20px;
    margin-bottom: 54px;
  }

  .ranking-item {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 15px;
    flex: 1 1 200px;
  }

  .ranking-description {
    display: flex;
    flex-wrap: wrap;
    margin-right: -30px;
    margin-left: -30px;

  }

  .special-condition {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 31px;
    text-align: right;
    color: #1E1E1E;
    margin-bottom: 200px;
  }
}

</style>