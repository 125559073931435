<template>
    <div class="holiday-item">
        <div class="day">{{ holiday.day }}</div>
        <div class="month">{{ holiday.month }}</div>
        <div class="description">{{ holiday.description }}</div>
    </div>
</template>

<script>
export default {
    name: "HolidayItem",
    props: ['holiday']
}
</script>

<style scoped>
.holiday-item {
    background: #FCFCFC;
    border: 0.3px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
    max-width: 100px;
    flex: 1 0 100px;
    text-align: center;
    padding: 7px 13px 11px;
    margin: 0 12px 25px;
}

.day {
    font-weight: bold;
    font-size: 26px;
    line-height: 50px;
    color: #000000;
}

.month {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 21px;
    color: #000000;
    padding-bottom: 12px;
    margin-top: -10px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
    margin-bottom: 6px;
}

.description {
    font-weight: normal;
    font-size: 10px;
    line-height: 19px;
    color: #000000;
    text-align: center;
}
</style>