<template>
    <div id="dimension" class="form-section">
        <h6 class="section-title">
            ابعاد
        </h6>

        <div class="section-fields">

            <the-input v-for="item in fieldList"
                       :key="item.name"
                       :model-value="formData[item.name]"
                       :item="item"
                       @input="updateFormData(item.name,$event)"
            />
        </div>
    </div>
</template>

<script>


import TheInput from "@/components/Product/TheInput";

export default {
    name: "TheDimensionSection",
    components: {TheInput},
    props: ['formData', 'fieldList'],
    emits:['update:formData'],
    data() {
        return {
            // formDate: this.modelValue
        }
    },
    methods: {
        updateFormData(field, e) {
            let a = this.formData
            a[field] = e
            this.$emit('update:formData', a)
        }
    }
}
</script>

<style scoped>

</style>