<template>

    <teleport to="body">
        <modal-base @close="closeModal" v-if="activeModal === 'map'" :modalIsOpen="modalIsOpen">
            <template #header>آدرس جدید</template>
            <template #default>
                <div class="address-modal-map">
                    <mapir :center="center" :apiKey="apiKey"></mapir>
                </div>
                <div class="address-modal-footer">
                    <p class="description">مرسوله ی شما به این آدرس ارسال خواهد شد.</p>
                    <button @click="activeModal = 'address'" class="btn">ثبت و افزودن جزئیات</button>
                </div>
            </template>
        </modal-base>
        <modal-base @close="closeModal" v-if="activeModal === 'address'" :modalIsOpen="modalIsOpen">
            <template #header>جزئیات آدرس</template>
            <template #default>
                <div class="address-detail">
                    <div class="address-wrap">
                        <div class="general">
                            <div class="form-group">
                                <select id="province" class="form-control" v-model="province" required>
                                    <option value="1">تهران</option>
                                    <option value="2">مشهد</option>
                                </select>
                                <label for="province">استان</label>
                                <label for="province">استان</label>
                            </div>
                            <div class="form-group">
                                <select id="city" class="form-control" v-model="city" required>
                                    <option value="1">تهران</option>
                                    <option value="2">خراسان</option>
                                </select>
                                <label for="city">شهر</label>
                            </div>
                            <div class="form-group">
                                <select id="neighborhood" class="form-control" v-model="neighborhood" required>
                                    <option value="1">ولیعصر</option>
                                    <option value="2">پاسداران</option>
                                </select>
                                <label for="neighborhood">محله</label>
                            </div>
                        </div>
                        <div class="partial">
                            <div class="form-group">
                                <input id="address" type="text" class="form-control not-valid" v-model="address"
                                       required>
                                <label for="address">نشانی پستی</label>
                            </div>
                            <div class="form-group">
                                <input id="number" type="text" class="form-control" v-model="number" required>
                                <label for="number">پلاک</label>
                            </div>
                            <div class="form-group">
                                <input id="postalCode" type="text" class="form-control" v-model="postalCode" required>
                                <label for="postalCode">کد پستی</label>
                            </div>
                        </div>
                    </div>
                    <div class="receiver-wrap">
                        <div class="form-group">
                            <input id="self-receiver" type="checkbox" v-model="selfReceiver">
                            <label for="self-receiver">گیرنده خودم هستم. </label>
                        </div>
                        <div class="receiver" v-if="!selfReceiver">
                            <div class="form-group">
                                <input id="name" type="text" class="form-control" v-model="name" required>
                                <label for="name">نام گیرنده</label>
                            </div>
                            <div class="form-group">
                                <input id="lastname" type="text" class="form-control" v-model="lastname" required>
                                <label for="lastname">نام خانوادگی گیرنده</label>
                            </div>
                            <div class="form-group">
                                <input id="mobile" type="text" class="form-control" v-model="mobile" required>
                                <label for="mobile">شماره موبایل</label>
                            </div>
                        </div>
                    </div>
                    <div class="footer">
                        <a @click.prevent="activeModal = 'map'" href="">اصلاح آدرس از روی نقشه <span
                                class="vector"></span></a>
                        <button type="button" class="btn" @click.prevent="closeModal">تایید و ثبت آدرس</button>
                    </div>
                </div>
            </template>
        </modal-base>
    </teleport>
</template>

<script>
import ModalBase from "@/components/Bases/ModalBase";
import {mapir} from 'mapir-vue';

export default {
    name: "AddAddressModal",
    components: {ModalBase, mapir},
    props: ['modalIsOpen'],
    emits:['close'],
    data() {
        return {
            province: "",
            city: "",
            neighborhood: "",
            selfReceiver: false,
            address: "",
            number: "",
            postalCode: "",
            name: "",
            lastname: "",
            mobile: "",
            apiKey: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImMzNjdlZWVhMTliNTAwYzhiODBkYWRhYTJjMGY1MGYxODMwMDYwNWJiOTE3YjNlYTI4MjM0NWJjOTZiMzA3OTAwY2RmY2M2ZTk0MTE5YzIyIn0.eyJhdWQiOiIxNDU2NiIsImp0aSI6ImMzNjdlZWVhMTliNTAwYzhiODBkYWRhYTJjMGY1MGYxODMwMDYwNWJiOTE3YjNlYTI4MjM0NWJjOTZiMzA3OTAwY2RmY2M2ZTk0MTE5YzIyIiwiaWF0IjoxNjI0MzU1NTAxLCJuYmYiOjE2MjQzNTU1MDEsImV4cCI6MTYyNjk0NzUwMSwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.lfivPcSQLLSLPAPfTwotm8KmZLc63lSiIPYTeGxSPHUjGpxKGTZLbefYVCs9eQpWH2CYyPXLlDG2QlARhLYxNztaeYOxt0yWETRL0pbxlI5mIrie1KSqgqIKKu1aGj9-J6imXGNkt7LUy3oY4rYuv__twR-y8GQ3_LnkHFQpDTLdfJfWT-isQuGE0AaUdsi5kiI5auxtjRydMwlWQQjtablpBpTUHjYKZ6a7xYnGKNxH7w9RzIPZNsHaIFlJpQE2aDdkmY70MMH5CBfxOKxT9qJSPDiCaSdYt0gJJuUDcPSXxfliIO_s9XOWoKV1_pxe7BEgMbhSMdqspllCc-8ILg',
            center: [51.420296, 30.732379],
            activeModal: 'map',
        }
    },
    methods: {
        closeModal() {
            this.activeModal = 'map';
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>

.address-modal-map {
  padding: 0 34px 22px;
  border-bottom: 0.3px solid rgba(0, 0, 0, 0.5);
  height: 50vh;
}

.address-modal-footer {
  padding-top: 21px;
  padding-bottom: 32px;

  .description {
    font-weight: normal;
    font-size: 11px;
    line-height: 21px;
    color: #000000;
    margin: 0 auto 14px;
    text-align: center;
  }

  .btn {
    background: #414A53;
    border-radius: 5px;
    color: #FFFFFF;
    border: none;
    padding: 9px 25px;
    margin: 0 auto;
    display: block;
  }


}

.address-detail {
  overflow-y: auto;
  padding-top: 10px;

  .address-wrap, .receiver-wrap {
    padding-right: 43px;
    padding-left: 43px;
    border-bottom: 0.3px solid rgba(0, 0, 0, 0.5);
    margin-bottom: 17px;
  }

  .general {
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    margin-bottom: 20px;
  }

  .receiver-wrap > .form-group:first-child {
    margin-bottom: 20px;

    label {
      margin-right: 10px;
    }
  }

  .partial, .receiver {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;

    > .form-group:first-child {
      flex: 1 1 400px;
    }

    .form-group {
      flex: 1 1 200px;
      margin-bottom: 20px;
      margin-right: 10px;
      margin-left: 10px;

    }

  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 35px;

    a {
      font-size: 12px;
      line-height: 23px;
      text-align: right;
      color: #FF7D0E;
      margin-bottom: 12px;
    }

    .vector {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.22638 6.95597H14.2408C15.4777 6.95597 15.4777 4.93019 14.2408 4.93019H3.22638L6.2303 1.76491C7.11381 0.815322 5.7591 -0.640707 4.87559 0.308877L0.34025 5.18341C-0.0720533 5.56324 -0.130954 6.25961 0.28135 6.70275L4.87559 11.6406C5.7591 12.5902 7.05491 11.1341 6.1714 10.1846L3.22638 6.95597Z' fill='%23FF7D0E'/%3E%3C/svg%3E%0A");
      display: inline-block;
      width: 16px;
      height: 12px;
      background-repeat: no-repeat;
      margin-right: 30px;
    }

    .btn {
      background: #414A53;
      border-radius: 5px;
      font-weight: bold;
      font-size: 15px;
      line-height: 29px;
      text-align: right;
      color: #FFFFFF;
      padding: 9px 30px;
      border: none;
    }
  }
}
</style>