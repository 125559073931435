import {createRouter, createWebHistory} from 'vue-router'
// import HomePage from "@/components/Home/HomePage";
// import LoginPage from "@/components/Login/LoginPage";
// import AuthPage from "@/components/Login/AuthPage";
// import CategoryPage from "@/components/Category/CategoryPage";
// import ProductPage from "@/components/Product/ProductPage";
// import Test from "@/components/Test";
// import ShippingPage from "@/components/Shipping/ShippingPage";
// import PanelPage from "@/components/Panel/PanelPage";
// import ProfileInfoPage from "@/components/Panel/ProfileInfoPage";
// import FinancialReportPage from "@/components/Panel/FinancialReportPage";
// import MyOrderPage from "@/components/Panel/MyOrderPage";
// import MyPackPage from "@/components/Panel/MyPackPage";
// import AddressPage from "@/components/Panel/AddressPage";
// import RankingPage from "@/components/Panel/RankingPage";
// import FileUploadPage from "@/components/Panel/FileUploadPage";
// import InvoicePage from "@/components/Invoice/InvoicePage";
import routes from "@/Libs/vue-router/routes";
const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

export default router;
