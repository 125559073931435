<template>
    <div class="main-title">
        <p><slot></slot></p>
        <div class="divider">
            <span class="point"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "MainTitle"
}
</script>

<style scoped lang="scss">
.main-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  text-align: center;
  margin-bottom: 35px;

  .divider {
    width: 100%;
    text-align: center;
    font-size: 0;
    line-height: 0;
    position: relative;

    span {
      display: inline-block;
      height: 10px;
      width: 10px;
      background: black;
      border-radius: 50%;
      transform: translate(0, -50%);
    }

    &::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 150px;
      right: 53%;
      top: 0;
      background: var(--secondary_color);
      @media screen and (min-width: 576px){
        right: 51%;
      }
    }

    &::before {
      content: "";
      position: absolute;
      height: 2px;
      width: 150px;
      left: 53%;
      top: 0;
      background: var(--secondary_color);
      @media screen and (min-width: 576px){
        left: 51%;
      }
    }
  }
}

</style>