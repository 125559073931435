<template>
    <div class="service container">
        <div class="service-entry">
            <main-title>آشنایی با کالا و خدمات سیزپک</main-title>
            <div class="service-item-wrap row">
                <service-item v-for="category in categories"
                              :key="category.id"
                              :title="category.name"
                              :href="category.href"
                              :description="category.description"
                              :src="category.default_image"/>
            </div>
        </div>
    </div>
</template>

<script>
import ServiceItem from "@/components/Home/ServiceItem";
import MainTitle from "@/components/Bases/MainTitle";

export default {
    name: "ServiceSection",
    components: {
        ServiceItem, MainTitle
    },
    props: ['categories'],
}
</script>

<style scoped lang="scss">

.service {

  background: white;
  padding-top: 30px;
}

.service-item-wrap {

}
</style>