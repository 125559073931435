<template>
    <div class="card">
        <div class="card-header">
            <slot name="header"></slot>
        </div>
        <div class="card-body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "radio-card",
    props: ['title', 'description']
}
</script>

<style lang="scss" scoped>

.card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0;
  width: 100%;
  flex: 1 1 250px;
  margin: 0 8px 10px;


  .card-header {
    padding: 5px 45px;
    background: #E0E0E0;
    border-radius: 5px 5px 0px 0px;
  }

  .card-body {
    background: #F4F4F4;
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 21px;
    text-align: right;
    color: rgba(0, 0, 0, 0.7);
    padding: 15px 45px;
  }

  .location-icon {
    position: relative;
    margin: 0;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      right: -30;
      width: 16px;
      height: 16px;
      background-image: url("~@/assets/images/icon/location-icon.svg");
    }
  }
}

</style>