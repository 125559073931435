<template>
    <div class="financial-report">
        <h6 class="main-title">گزارش های مالی</h6>
        <div class="financial-filter">
            <form @click.prevent="">

                <div class="filter-fields">
                    <div class="form-group">
                        <input class="form-control" type="text" id="description" v-model="description" required>
                        <label for="description">شرح</label>
                    </div>
                    <div class="form-group">
                        <input class="form-control" id="orderId" type="text" v-model="orderId" required>
                        <label for="orderId">شماره سفارش</label>
                    </div>

                    <date-picker-base v-model="datea"/>
                    <date-picker-base v-model="dateb"/>


                    <DatePicker v-model="dateb" class="field-item"/>

                    <button class="search-btn">جستجو</button>
                </div>
            </form>
        </div>


        <div class="financial-table">
            <table class="table">
                <thead>
                <tr>
                    <th v-for="item in tableTitle" :key="item" scope="col">{{ item }}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>Otto</td>
                    <td>Otto</td>
                    <td>Otto</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                </tr>
                <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="more-report">مشاهده بیشتر گزارشات</div>

    </div>

    <div class="financial-document">
        <h6 class="main-title">آپلود اسناد مالی</h6>
        <div v-if="!documentUploaderIsActive" class="description-wrap">
            <p class="description">لطفا اسناد مالی را در این قسمت بارگذاری نمایید.</p>
            <button class="upload-link" @click.prevent="documentUploaderIsActive = true">آپلود اسناد ></button>
        </div>
        <transition name="fade">
            <div v-if="documentUploaderIsActive" class="uploader-section">
                <form @submit.prevent="">
                    <div class="uploader-wrap">
                        <div class="uploader-field">
                            <div class="form-group">
                                <input type="text" id="orderId" v-model="orderId" class="form-control" required>
                                <label for="orderId">شماره سفارش</label>
                            </div>
                            <div class="form-group">
                                <textarea id="description" class="form-control" v-model="description"
                                          required></textarea>
                                <label for="description">شرح</label>
                            </div>
                        </div>
                        <div class="uploader">
                            <div class="uploader-entry">
                                <img :src="require('@/assets/images/svg/uploader.svg')">
                                <p>+ بارگذاری تصویر</p>
                            </div>
                        </div>
                    </div>
                    <button class="uploader-btn">ارسال</button>
                </form>
            </div>
        </transition>
    </div>
</template>

<script>
import DatePickerBase from "@/components/Bases/DatePickerBase";
import "vue3-persian-datepicker/dist/datepicker.min.css";
// import DatePicker from '@/components/Bases/picker/VuePersianDatetimePicker.vue'


export default {
    name: "FinancialReportPage",
    data() {
        return {
            date: '',
            documentUploaderIsActive: false,
            description: "",
            orderId: "",
            datea: "",
            dateb: "",
            tableTitle: ['ردیف', 'تاریخ', 'شرح', 'شماره سفارش', 'بدهکار', 'بستانکار', 'مانده', 'جزئیات']
        }
    },
    components: {DatePickerBase},
}
</script>

<style lang="scss" scoped>
.financial-report {
  margin-bottom: 23px;

  .financial-filter {
    margin-bottom: 30px;
  }

  .filter-fields {
    display: grid;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

    .form-control, .date-control > input {
      background-color: transparent;
    }

    .form-control ~ label, .form-control:focus ~ label, .form-control:valid ~ label {
      background-color: #F3F3F3;
    }
  }


  .search-btn {
    background: #414A53;
    border-radius: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 29px;
    color: #FFFFFF;
    padding: 8px 50px;
    border: none;
    text-align: center;
    grid-column: -1/-2;
  }

  .financial-table {
    overflow-x: auto;
    width: 100%;
  }

  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: #dee2e6;
    background: #F7F7F7;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    thead {
      background: #414A53;
      border-radius: 5px 5px 0 0;

      th {
        padding: 9px 30px;
        //border-radius: 5px 5px 0 0;
        border-left: 1px solid #FFFFFF;
        border-right: 1px solid #FFFFFF;
        font-size: 14px;
        line-height: 27px;
        text-align: right;
        color: #FFFFFF;
        white-space: nowrap;
        font-style: normal;
        font-weight: normal;

        &:first-child {
          border-top-right-radius: 5px;
          padding: 9px 20px;
        }

        &:last-child {
          border-top-left-radius: 5px;
        }

        &:nth-child(3) {
          padding: 9px 60px;
        }
      }
    }

    tbody {
      td, & th {
        text-align: center;
        padding: 15px 30px 10px;
        border-right: 1px solid rgba(65, 74, 83, 0.5);
        border-left: 1px solid rgba(65, 74, 83, 0.5);
        border-bottom: 1px solid rgba(65, 74, 83, 0.5);
        min-height: 50px;
      }
    }
  }

  .more-report {
    background: #FFD2A6;
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 27px;
    color: #1E1E1E;
    padding: 9px 0;
    text-align: center;
  }


}

.financial-document {
  .description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 23px;
    text-align: right;
    color: #1E1E1E;
  }

  .upload-link {
    font-weight: bold;
    font-size: 14px;
    line-height: 27px;
    text-align: right;
    color: #FF7D0E;
    border: none;
    padding: 0;
    background-color: transparent;
    margin-bottom: 232px;
  }

  .uploader-section {
    margin-bottom: 54px;

  }

  .uploader-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .uploader-field {
    flex: 1 0 250px;
    padding-right: 15px;
    padding-left: 15px;

    .form-control {
      margin-bottom: 30px;
      background-color: var(--profile_background);
    }

    label {
      background-color: var(--profile_background);
    }
  }

  .uploader {
    flex: 1 0 250px;
    padding-right: 15px;
    padding-left: 15px;

    .uploader-entry {
      background: #F2F2F2;
      border: 1px dashed rgba(30, 30, 30, 0.5);
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 28px;
      padding-bottom: 24px;
      margin-bottom: 30px;
    }

    img {
      display: inline-block;
      margin-bottom: 24px;
    }

    p {
      font-size: 13px;
      line-height: 25px;
      text-align: right;
      color: #1E1E1E;
    }
  }

  .uploader-btn {
    background: #414A53;
    border-radius: 5px;
    color: #FFFFFF;
    padding: 9px 72px;
    border: none;
    display: block;
    margin: 0 auto;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }


}
</style>