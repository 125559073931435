<template>
    <div class="form-section">
        <h6 class="section-title">
            تیراژ
        </h6>
        <div class="section-fields">
            <template v-for="item in fieldList" :key="item.name">
                <the-input
                        :model-value="formData[item.name]"
                        :item="item"
                        @input="updateFormData( item.name, $event)"
                />
                <div class="form-group">
                    <div class="form-control-text">
                        <span class="label">{{ item.label }} اقتصادی:</span>
                        <span class="content">5000</span>
                        <span class="unit">عدد</span>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import TheInput from "@/components/Product/TheInput";

export default {
    name: "TheCountSection",
    components: {TheInput},
    props: ['formData', 'fieldList'],
    emits: ['update:modelValue'],
    methods: {
        updateFormData(field, e) {
            let a = this.formData
            a[field] = e
            this.$emit('update:formData', a)

        }
    }
}
</script>

<style scoped>

</style>