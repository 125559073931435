<template>
    <div class="my-pack">
        <h6 class="main-title">بسته های من</h6>
        <div class="my-pack-filter">
            <form @click.prevent="">
                <div class="filter-fields">
                    <div class="form-group">
                        <select id="condition" class="form-control" v-model="condition" required>
                            <option value="" selected disabled></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </select>
                        <label for="condition">جستجوی وضعیت</label>
                    </div>
                    <div class="form-group">
                        <select id="level" class="form-control" v-model="level" required>
                            <option value="" selected disabled></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </select>
                        <label for="level">طبقه</label>
                    </div>

                <button class="search-btn">جستجو</button>
                </div>
            </form>
        </div>


        <div class="my-pack-table">
            <table class="table">
                <thead>
                <tr>
                    <th v-for="item in tableTitle" :key="item" scope="col">{{ item }}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>Otto</td>
                    <td>Otto</td>
                    <td>Otto</td>
                    <td><img :src="require('@/assets/images/icon/chart-icon.svg')" alt=""></td>
                    <td> &#60; </td>
                </tr>
                <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <th scope="row"></th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="more-report">مشاهده بیشتر گزارشات</div>

    </div>

</template>

<script>
export default {
    name: "MyPackPage",
    data() {
        return {
            documentUploaderIsActive: false,
            condition:"",
            level:"",
            tableTitle: ['ردیف', 'طبقه', 'نام کالا', 'تیراژ', 'قیمت واحد', 'جمع کل (ریال)', 'نمودار نوسانات', 'جزئیات']
        }
    }
}
</script>

<style lang="scss" scoped>
.my-pack {
  margin-bottom: 187px;

  .main-title {
    margin-bottom: 10px;
  }

  .my-pack-filter {
    overflow: hidden;
    margin-bottom: 30px;
    padding-top: 15px;
  }

  .filter-fields {
    display: grid;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

    .form-control, .date-control > input {
      background-color: transparent;
    }

    .form-control~label, .form-control:focus ~ label, .form-control:valid ~ label {
      background-color: #F3F3F3;
    }
  }


  .search-btn {
    background: #414A53;
    border-radius: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 29px;
    color: #FFFFFF;
    padding: 8px 50px;
    border: none;
    text-align: center;
    grid-column: -1/-2;
  }

  .my-pack-table {
    overflow-x: auto;
    width: 100%;
  }

  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: #dee2e6;
    background: #F7F7F7;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    thead {
      background: #414A53;
      border-radius: 5px 5px 0 0;

      th {
        padding: 9px 30px;
        //border-radius: 5px 5px 0 0;
        border-left: 1px solid #FFFFFF;
        border-right: 1px solid #FFFFFF;
        font-size: 14px;
        line-height: 27px;
        text-align: right;
        color: #FFFFFF;
        white-space: nowrap;
        font-style: normal;
        font-weight: normal;

        &:first-child {
          border-top-right-radius: 5px;
          padding: 9px 20px;
        }

        &:last-child {
          border-top-left-radius: 5px;
        }

        &:nth-child(3) {
          padding: 9px 60px;
        }
      }
    }

    tbody {
      td, & th {
        text-align: center;
        padding: 15px 30px 10px;
        border-right: 1px solid rgba(65, 74, 83, 0.5);
        border-left: 1px solid rgba(65, 74, 83, 0.5);
        border-bottom: 1px solid rgba(65, 74, 83, 0.5);
        min-height: 50px;
      }
    }
  }

  .more-report {
    background: #FFD2A6;
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 27px;
    color: #1E1E1E;
    padding: 9px 0;
    text-align: center;
  }


}

</style>