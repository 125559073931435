<template>
    <div class="banner container">
        <div class="banner-entry">
            <div class="right">
                <div class="banner-image">
                    <img :src="require('@/assets/images/banner2.png')" alt="">
                </div>
            </div>
            <div class="left">
                <div class="banner-content">
                    <img :src="require('@/assets/images/banner1.png')" alt="">
                </div>
                <div class="call">
                    <button type="button">قیمت بسته منو بده</button>
                </div>
            </div>
        </div>
    </div>


    <div class="sub-banner container">
        <div class="sub-banner-entry">
            <span class="icon"><img :src="require('@/assets/images/icon/pack-icon.svg')" alt=""></span>
            شما کافیه فقط یکبار (طول، عرض، ارتفاع) بسته را به سیزپک بدی، هر لحظه و تا همیشه قیمت روز بسته بندی رو
            بهت میدم!!!
        </div>
    </div>


</template>

<script>
export default {
    name: "TheBanner"
}
</script>

<style scoped lang="scss">

.banner {
  background: var(--primary_color);
  min-height: 300px;
  max-height: 100vh;
  flex-direction: column;
  align-items: center;
}

.banner-entry {
  position: relative;
  flex-grow: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 992px) {
    align-items: stretch;
  }

  .right {

    @media screen and (min-width: 992px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .banner-image {
      @media screen and (min-width: 992px) {
        margin-bottom: -30px;
      }

      > img {
        width: 100%;
        display: inline-block;
      }
    }
  }

  .left {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(256, 2556, 256, 0.75);
    padding: 10px 20px;

    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @media screen and (min-width: 992px) {
      position: relative;
      background: none;
      display: block;
    }

    .banner-content img {
      width: 100%;
      max-width: 450px;
    }

    //margin-top: 17px;
  }

  .call {
    text-align: center;
    margin-top: 10px;

    button {
      display: inline-block;
      font-style: normal;
      font-weight: bold;
      color: #ffffff;
      font-size: 18px;
      line-height: 34px;
      background: #414A53;
      border-radius: 5px;
      padding: 5px 22px 11px 25px;
    }
  }

}

.sub-banner {
  background: var(--light_grey_color);

  .sub-banner-entry {
    text-align: center;
    margin: 16px 0 24px;
  }

  .icon {
    margin-left: 10px;
  }
}

</style>